// src/services/ClientServices/NurseRoutes.js
import apiClient from '../apiservice';
import getClinicId from '../getClinicId';

const nurseApi = {
    addNurse: async (nurseData) => {
        try {
            const {clinic_id} = getClinicId(); // Get the dynamic clinic_id
            console.log(`Adding nurse to clinic_id: ${clinic_id}`);
            const response = await apiClient.post(`/clinics/${clinic_id}/add_nurse`, nurseData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Add Nurse Response:', response);
            return response.data;
        } catch (error) {
            console.error('Error adding nurse:', error);
            throw error;
        }
    },
    getNurses: async () => {
        try {
            const {clinic_id} = getClinicId();
            console.log(`Fetching nurses for clinic_id: ${clinic_id}`);
            const response = await apiClient.get(`/clinics/${clinic_id}/get_all_nurses`);
            console.log('Get Nurses Response:', response);
            return response.data;
        } catch (error) {
            console.error('Error fetching nurses:', error);
            throw error;
        }
    },
    readNurse: async (nurse_id) => {
        try {
            const {clinic_id} = getClinicId();
            console.log(`Reading nurse ${nurse_id} from clinic_id: ${clinic_id}`);
            const response = await apiClient.get(`/clinics/${clinic_id}/nurses/${nurse_id}/get_nurse`);
            console.log('Read Nurse Response:', response);
            return response.data;
        } catch (error) {
            console.error('Error reading nurse:', error);
            throw error;
        }
    },
    updateNurse: async (nurse_id, nurseData) => {
        try {
            const {clinic_id} = getClinicId();
            console.log(`Updating nurse ${nurse_id} in clinic_id: ${clinic_id}`);
            const response = await apiClient.put(`/clinics/${clinic_id}/nurses/${nurse_id}/update`, nurseData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Update Nurse Response:', response);
            return response.data;
        } catch (error) {
            console.error('Error updating nurse:', error);
            throw error;
        }
    },
};

export default nurseApi;
