// VitalCard.js
import React from 'react';

// Define color ranges for vital parameters
const vitalRanges = {
    bp: {
        SBP: [
            { range: [0, 60], color: '#00d3c7' },
            { range: [80, 100], color: '#fef5e4' },
            { range: [120, 140], color: '#FFF2E5' },
            { range: [140, 160], color: '#ffe5e6' },
            { range: [160,  Infinity], color: '#ffe5e6' },
        ],
        DBP: [
            { range: [0, 40], color: '#ffe5e6' },
            { range: [40, 60], color: '#fef5e4' },
            { range: [60, 80], color: '#FFF2E5' },
            { range: [90, 110], color: '#ffe5e6' },
            { range: [110, 130], color: '#ffe5e6' },
            { range: [130,  Infinity], color: '#ffe5e6' },
        ],
        hr: [
            { range: [0, 19], color: '#ffe5e6' },
            { range: [20, 39], color: '#FFF2E5' },
            { range: [40, 59], color: '#fef5e4' },
            { range: [101, 110], color: '#ffe5e6' },
            { range: [111, 130], color: '#ffe5e6' },
            { range: [130,  Infinity], color: '#ffe5e6' },
        ],
        rr: [ 
            { range: [0, 3], color: '#ffe5e6' },
            { range: [4, 7], color: '#fef5e4' },
            { range: [8, 11], color: '#FFF2E5' },
            { range: [21, 25], color: '#FFF2E5' },
            { range: [26, 30], color: '#FFF2E5' },
            { range: [30, Infinity], color: '#ffe5e6' },
        ],
        spo2: [
            { range: [0, 85], color: '#ffe5e6' },
            { range: [85, 90], color: '#FFF2E5' },
            { range: [90, 94], color: '#fef5e4' },
        ],
        skt: [
            { range: [0, 30], color: '#ffe5e6' },
            { range: [30, 37], color: '#fef5e4' },
            { range: [37, Infinity], color: '#ffe5e6' },
        ],
    },
};

const VitalCard = ({ label, value, type }) => {
    // Function to determine background color based on value and ranges
    const getBackgroundColor = (type, value) => {
        const ranges = vitalRanges.bp[type]; // Example for blood pressure
        if (!ranges) return '#f9fafb'; // Default color if no ranges are defined

        for (const { range, color } of ranges) {
            if (value >= range[0] && value < range[1]) {
                return color;
            }
        }
        return '#f9fafb'; // Default color if no match found
    };

    const backgroundColor = getBackgroundColor(type, value);

    return (
        <div
            style={{
                background: backgroundColor,
                padding: '10px',
                borderRadius: '8px',
                textAlign: 'center',
            }}
        >
            <div style={{ fontSize: '14px', display: 'flex', gap: '8px' }}>
                {label}
                <div>{value !== undefined ? value : 'N/A'}</div>
            </div>
        </div>
    );
};

export default VitalCard;
