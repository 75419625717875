/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Table } from "antd";
import { onShowSizeChange, itemRender } from '../Pagination';
import {
  blogimg10, imagesend, pdficon, pdficon3, pdficon4, plusicon, refreshicon, searchnormal, error02, dangericon
} from '../imagepath';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import doctorApi from '../../services/ClientServices/DoctorRoutes';
import { useDoctor } from "../../context/DoctorContext";
import { useNavigate } from 'react-router-dom';
import StatusButton from '../../statusbutton';
import { useLocation } from 'react-router-dom';
import { store } from "../../../src/utils/redux/store";

const DoctorList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const auth = store.getState().auth; // Access the auth state from Redux store
  const navigate = useNavigate();
  const { setDoctorId } = useDoctor();
  const location = useLocation();
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [filteredData, setFilteredData] = useState([]);


  // Function to fetch doctors data
  const fetchDoctors = async () => {
    try {
      const data = await doctorApi.getDoctors();
      setDataSource(data.doctors || [])
      setFilteredData(data.doctors || []);
      setError(null);
    } catch (error) {
      setError(error);
      setDataSource([]); 
      setFilteredData([]);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  
    const filtered = dataSource.filter((doctor) => {
      const fullName = `${doctor.first_name} ${doctor.last_name}`.toLowerCase();
      const searchValue = value.toLowerCase();
  
      return (
        fullName.includes(searchValue) || 
        doctor.mobile.includes(searchValue) || 
        doctor.email.toLowerCase().includes(searchValue) ||
        doctor.departments.join(", ").toLowerCase().includes(searchValue) ||
        doctor.specialities.join(", ").toLowerCase().includes(searchValue)
      );
    });
  
    setFilteredData(filtered);
  };
  

  // useEffect hook to fetch doctors after the component mounts and when auth changes
  useEffect(() => {
    if (auth.authenticated && auth.clinic_id) {
      fetchDoctors();
    }
  }, [auth.authenticated, auth.clinic_id]); // Dependencies ensure fetchDoctors runs after these are available

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleEditClick = (id) => {
    setDoctorId(id); // Set nurseId in context
    navigate('/editdoctor'); // Navigate to edit page
  };

  const handleViewClick = (id) => {
    setDoctorId(id);
    navigate('/editdoctor', { state: { type: 'view' } });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "first_name",
      render: (text, record) => `Dr. ${record.first_name} ${record.last_name}`,
      sorter: (a, b) => a.first_name.localeCompare(b.first_name)
    },
    {
      title: "Department",
      dataIndex: "departments",
      render: departments => departments.join(", "),
      sorter: (a, b) => a.departments.length - b.departments.length
    },
    {
      title: "Specialization",
      dataIndex: "specialities",
      render: specialities => specialities.join(", "),
      sorter: (a, b) => a.specialities.length - b.specialities.length
    },
    {
      title: "Degree",
      dataIndex: "degree",
      sorter: (a, b) => a.degree.length - b.degree.length
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
      render: (text, record) => (
        <Link to="#">{record.mobile}</Link>
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.length - b.status.length,
      render: (status, record) => <StatusButton 
      status={status} 
      mobileNumber={record.mobile}
      isMobileVerified={record.is_mobile_verified}
      isEmailVerified={record.is_email_verified}
      userEmail={record.email}
      onStatusClick={() => console.log(`Status: ${status}`)} />,

    },
    {
      title: "",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="text-end">
          <button onClick={() => handleEditClick(record.id)} className="btn btn-primary me-2">Edit</button>
          <button onClick={() => handleViewClick(record.id)} className="btn btn-secondary">View</button>

          {/* Other actions like Delete can be added here */}
        </div>
      ),
    }
  ];

  return (
    <>
      {/* <Header />
      <Sidebar id='menu-item2' id1='menu-items2' activeClassName='doctor-list' /> */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Doctors </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Doctor List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {error ? (
            <div className="main-wrapper error-wrapper">
              <div className="error-box">
                <img className="img-fluid" src={error02} alt="Logo" />
                <h3>
                  <img
                    className="img-fluid mb-0"
                    src={dangericon}
                    alt="Logo"
                  />{" "}
                  Service Unavailable
                </h3>
                <p>Sorry, an error has occured, and we're working to fix the problem.</p>
                <Link to="/" className="btn btn-primary go-home">
                  Back to Home
                </Link>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Doctor List</h3>
                            <div className="doctor-search-blk">
                              <div className="top-nav-search table-search-blk">
                                <form>
                                  <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    className="form-control"
                                    placeholder="Search here"
                                  />
                                  <Link className="btn">
                                    <img src={searchnormal} alt="#" />
                                  </Link>
                                </form>
                              </div>
                              <div className="add-group">
                                <Link
                                  to="/add-doctor"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-primary doctor-refresh ms-2"
                                  onClick={fetchDoctors}
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        pagination={{
                          total: dataSource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={filteredData}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                        style={{
                          backgroundColor: '#f2f2f2', // Replace with your desired background color for the table
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Todo - notifications box */}
      </div>
      <div id="delete_patient" className="modal fade delete-modal" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46} />
              <h3>Are you sure want to delete this ?</h3>
              <div className="m-t-20">
                {" "}
                <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                  Close
                </Link>
                <button type="submit" className="btn btn-danger">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorList;
