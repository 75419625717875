// src/services/ClientServices/DoctorRoutes.js
import apiClient from '../apiservice';
import getClinicId from '../getClinicId';

const doctorApi = {
    addDoctor: async (doctorData) => {
        try {
            const { clinic_id} = getClinicId();
            const response = await apiClient.post(`/clinics/${clinic_id}/add_doctor`, doctorData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error adding doctor:', error);
            throw error;
        }
    },
    getDoctors: async () => {
        try {
            const { clinic_id} = getClinicId();
            const response = await apiClient.get(`/clinics/${clinic_id}/get_all_doctors`);
            return response.data;
        } catch (error) {
            console.error('Error fetching doctors:', error);
            throw error;
        }
    },
    readDoctor: async (doctor_id) => {
        try {
            const { clinic_id} = getClinicId();
            const response = await apiClient.get(`/clinics/${clinic_id}/doctors/${doctor_id}/get_doctor`);
            return response.data;
        } catch (error) {
            console.error('Error reading doctor:', error);
            throw error;
        }
    },
    updateDoctor: async (doctor_id, doctorData) => {
        try {
            const { clinic_id} = getClinicId();
            const response = await apiClient.put(`/clinics/${clinic_id}/doctors/${doctor_id}/update`, doctorData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error updating doctor:', error);
            throw error;
        }
    },
};

export default doctorApi;
