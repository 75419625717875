/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { favicon, imagesend } from "../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { DatePicker } from "antd";
import Select from "react-select";
import doctorApi from "../../services/ClientServices/DoctorRoutes";
import { Modal } from 'bootstrap';
import CreatableSelect from 'react-select/creatable';
import { useDoctor } from "../../context/DoctorContext";
import { useLocation } from 'react-router-dom';
import SuccessIcon from '../../assets/img/alert-success.256x256.png';
import usePostalCode from "../../hooks/usePostalCode";
import DeleteIcon from "../../assets/img/delete.png";
import { useNavigate } from 'react-router-dom';

const EditDoctor = ({ clinic_id }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState([]);
  const [picturePreview, setPicturePreview] = useState(null);
  const [signaturePreview, setSignaturePreview] = useState(null);
  const [pictureFileName, setPictureFileName] = useState('');
  const [signatureFileName, setSignatureFileName] = useState('');
  const { doctorId } = useDoctor();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const isViewMode = location.state?.type === 'view';
  const [activeTab, setActiveTab] = useState('general');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();

  const departmentOptions = [
    { value: "Cardiology", label: "Cardiology" },
    { value: "Orthopedic", label: "Orthopedic" },
    { value: "Radiology", label: "Radiology" },
  ];

  const specialityOptions = [
    { value: "Surgical", label: "Surgical" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDepartmentChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.__isNew__ ? option.value : option.value)
      : [];
    setFormData({
      ...formData,
      departments: selectedValues,
    });
    setSelectedDepartment(selectedOptions);
  };

  const handleSpecialityChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.__isNew__ ? option.value : option.value)
      : [];
    setFormData({
      ...formData,
      specialities: selectedValues,
    });
    setSelectedSpeciality(selectedOptions);
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        switch (name) {
          case 'picture':
            setPicturePreview(reader.result);
            setPictureFileName(file.name);
            setFormData({ ...formData, picture: file });
            setIsPictureUpdated(true);
            break;
          case 'signature':
            setSignaturePreview(reader.result);
            setSignatureFileName(file.name);
            setFormData({ ...formData, signature: file });
            setIsSignatureUpdated(true);
            break;
          default:
            break;
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    account_holder_name: "",
    account_number: "",
    ifsc_code: "",
    bank_branch: "",
    pan_number: "",
    facebook_id: "",
    twitter_id: "",
    linkedin_id: "",
    instagram_id: "",
    address: "",
    pin_code: "",
    city: "",
    state: "",
    country: "",
    mobile: "",
    email: "",
    date_of_birth: "",
    aadhar_number: "",
    degree: "",
    blood_group: "",
    specialities: [],
    license_number: "",
    departments: [],
    status: "",
    picture: null,
    signature: null,
  });

  const [isPictureUpdated, setIsPictureUpdated] = useState(false);
  const [isSignatureUpdated, setIsSignatureUpdated] = useState(false);

  useEffect(() => {
    const fetchDoctorData = async () => {
      setLoading(true); // Start loading
      try {
        if (!Number.isInteger(doctorId)) {
          throw new Error('Invalid doctor_id, must be an integer');
        }

        const response = await doctorApi.readDoctor(doctorId);
        if (response && response.doctors && response.doctors.length > 0) {
          const doctorDetails = response.doctors[0] || {};
          setFormData(doctorDetails);

          // Set selected city, country, and state
          setSelectedCity({ value: doctorDetails.city || '', label: doctorDetails.city || '' });
          setSelectedCountry({ value: doctorDetails.country || '', label: doctorDetails.country || '' });
          setSelectedState({ value: doctorDetails.state || '', label: doctorDetails.state || '' });

          // Map and set departments
          setSelectedDepartment(
            (doctorDetails.departments || []).map((dept) => ({
              value: dept,
              label: dept,
            }))
          );

          // Map and set specialities
          setSelectedSpeciality(
            (doctorDetails.specialities || []).map((spec) => ({
              value: spec,
              label: spec,
            }))
          );

          // Handle picture and signature preview
          if (doctorDetails.picture) {
            setPicturePreview(`data:image/jpeg;base64,${doctorDetails.picture}`);
            setPictureFileName('Existing Image');
          }
          if (doctorDetails.signature) {
            setSignaturePreview(`data:image/jpeg;base64,${doctorDetails.signature}`);
            setSignatureFileName('Existing Image');
          }
          setIsPictureUpdated(false);
          setIsSignatureUpdated(false);
        } else {
          console.error('No doctor data found');
          setError('No doctor data found');
        }
      } catch (error) {
        console.error('Error fetching doctor data:', error);
        setError('Failed to load doctor data');
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchDoctorData();
  }, [clinic_id, doctorId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    const doctorRequest = {};

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (key === 'departments' || key === 'specialities') {
          doctorRequest[key] = formData[key];
        } else if (key === 'pin_code') {
          doctorRequest[key] = parseInt(formData[key], 10);
        } else if (key === 'date_of_birth') {
          doctorRequest[key] = formData[key];
        } else {
          doctorRequest[key] = formData[key];
        }
      }
    });

    data.append('doctor_request', JSON.stringify(doctorRequest));

    if (isPictureUpdated && formData.picture) data.append('picture', formData.picture);
    if (isSignatureUpdated && formData.signature) data.append('signature', formData.signature);

    try {
      const response = await doctorApi.updateDoctor(doctorId, data);
      console.log('Doctor updated successfully:', response);
      setIsSuccessful(true);
      setPopupMessage(response.message);
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    } catch (error) {
      console.error('Error updating doctor:', error.response ? error.response.data : error.message);
      setPopupMessage('Failed to update doctor. Please try again.');
      setIsSuccessful(false);
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    }
  };

  const handleClose = () => {
    const popupMessageModal = Modal.getInstance(document.getElementById('popupMessageModal'));
    popupMessageModal.hide();
    if (isSuccessful) {
      navigate('/doctorlist');
    }
  };

  const [show, setShow] = useState(false);
  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };
  const loadFile = (event) => {
    // Handle file loading logic here
  };
  return (
    <div>
      {/*<Header /> <Sidebar id="menu-item2" id1="menu-items2" activeClassName="edit-doctor" />*/}
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Doctors </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Edit Doctor</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {isViewMode ? (
              <div className="row mt-4">
                <div className="col-xl-3 col-md-4">
                  <div className="widget settings-menu setting-list-menu">
                    <ul className='mb-0'>
                      <li className={`nav-item ${activeTab === 'general' ? 'active' : ''}`}>
                        <span
                          className="nav-link"
                          onClick={() => setActiveTab('general')}
                        >
                          <i className="fa fa-cog me-2" /> <span>General Settings</span>
                        </span>
                      </li>
                      <li className={`nav-item ${activeTab === 'social' ? 'active' : ''}`}>
                        <span
                          className="nav-link"
                          onClick={() => setActiveTab('social')}
                        >
                          <i className="fa fa-bookmark me-2" /> <span>Social Media Settings</span>
                        </span>
                      </li>
                      <li className={`nav-item ${activeTab === 'bank' ? 'active' : ''}`}>
                        <span
                          className="nav-link"
                          onClick={() => setActiveTab('bank')}
                        >
                          <i className="fas fa-university me-2" /> <span>Bank Settings</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xl-9 col-md-8">
                  {activeTab === 'general' && (
                    <div className="card invoices-settings-card">
                      <div className="card-header px-3">
                        <h5 className="card-title">General Settings</h5>
                      </div>
                      <div className="card-body">
                        {/* Form */}
                        <form action="#" className="invoices-settings-form">
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Full Name
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={`${formData.first_name} ${formData.last_name}`} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Date of Birth
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.date_of_birth} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="phone"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Mobile
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                value={formData.mobile}
                                defaultValue="+91"
                              />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="email"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Email
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.email} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Blood Group
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.blood_group} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Gender
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.gender} className="form-control" />
                            </div>
                          </div>

                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Degree
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.degree} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                               Specialization
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.specialities} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Department
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.departments} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group ">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Doctor Picture
                            </label>
                            <div className="col-sm-9">
                              <div className="invoices-upload-btn">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="image"
                                  id="file"
                                  // onchange="loadFile(event)"
                                  className="hide-input"
                                />
                                <label htmlFor="file" className="upload">
                                  upload File
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center form-group ">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                            Doctor Signature
                            </label>
                            <div className="col-sm-9">
                              <div className="invoices-upload-btn">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="image"
                                  id="file"
                                  // onchange="loadFile(event)"
                                  className="hide-input"
                                />
                                <label htmlFor="file" className="upload">
                                  upload File
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Address
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.address} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="zipcode"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Postal Code
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.pin_code} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              City
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.city} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Country
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.country} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              State
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.state} className="form-control" />
                            </div>
                          </div>
                          <div className="invoice-setting-btn text-end" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px', marginBottom: '10px' }}> 
                            <button type="submit" className="btn cancel-btn me-2">
                            Cancel
                          </button>
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </form>
                        {/* /Form */}
                      </div>
                    </div>

                  )}

                  {activeTab === 'social' && (
                    <div className="card invoices-settings-card">
                      <div className="card-header px-3">
                        <h5 className="card-title">Social Media Settings</h5>
                      </div>
                      <div className="card-body">
                        <form action="#" className="invoices-settings-form">
                          {/* Add your social media settings form fields here */}
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Facebook ID
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.facebook_id} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Twitter ID
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.twitter_id} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              Instagram ID
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.instagram_id} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label
                              htmlFor="text"
                              className="col-sm-3 col-form-label input-label"
                            >
                              LinkedIn ID
                            </label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.linkedin_id} className="form-control" />
                            </div>
                          </div>
                          {/* Add more fields as needed */}
                          <div className="invoice-setting-btn text-end" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px', marginBottom: '10px' }}> <button type="submit" className="btn cancel-btn me-2">
                            Cancel
                          </button>
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}

                  {activeTab === 'bank' && (
                    <div className="card invoices-settings-card">
                      <div className="card-header px-3">
                        <h5 className="card-title">Bank Settings</h5>
                      </div>
                      <div className="card-body">
                        <form action="#" className="invoices-settings-form">
                          {/* Add your bank settings form fields here */}
                          <div className="row align-items-center form-group">
                            <label htmlFor="accountHolderName" className="col-sm-3 col-form-label input-label">Account Holder Name</label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.account_holder_name} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label htmlFor="accountHolderName" className="col-sm-3 col-form-label input-label">Account Number</label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.account_number} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label htmlFor="accountHolderName" className="col-sm-3 col-form-label input-label">Bank Branch</label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.bank_branch} className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center form-group">
                            <label htmlFor="accountHolderName" className="col-sm-3 col-form-label input-label">IFSC Code</label>
                            <div className="col-sm-9">
                              <input type="text" value={formData.ifsc_code} className="form-control" />
                            </div>
                          </div>
                          {/* Add more fields as needed */}
                          <div className="invoice-setting-btn text-end" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px', marginBottom: '10px' }}> <button type="submit" className="btn cancel-btn me-2">
                            Cancel
                          </button>
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-heading">
                              <h4>Doctor Details</h4>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                First Name <span className="login-danger">*</span>
                              </label>
                              <input
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Last Name <span className="login-danger">*</span>
                              </label>
                              <input
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms cal-icon">
                              <label>
                                Date Of Birth <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control datetimepicker"
                                value={formData.date_of_birth}
                                name="date_of_birth"
                                disabled
                                dateFormat="yyyy-MM-dd"
                              />
                            </div>
                          </div>


                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Mobile <span className="login-danger">*</span>
                              </label>
                              <input
                                name="mobile"
                                value={formData.mobile}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Email <span className="login-danger">*</span>
                              </label>
                              <input
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="form-control"
                                type="email"
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group select-gender">
                              <label className="gen-label">
                                Gender <span className="login-danger">*</span>
                              </label>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="gender"
                                    value="Male"
                                    className="form-check-input"
                                    checked={formData.gender === 'Male'}
                                    onChange={handleChange}
                                  />
                                  Male
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="gender"
                                    value="Female"
                                    className="form-check-input"
                                    checked={formData.gender === 'Female'}
                                    onChange={handleChange}
                                  />
                                  Female
                                </label>
                              </div>
                              <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Other"
                                  className="form-check-input"
                                  checked={formData.gender === 'Other'}
                                  onChange={handleChange}
                                />
                                Others
                              </label>
                            </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Department <span className="login-danger">*</span>
                              </label>
                              <CreatableSelect
                                name="departments"
                                menuPosition={'fixed'}
                                value={selectedDepartment}
                                onChange={handleDepartmentChange}
                                options={departmentOptions}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                id="search-commodity"
                                components={{ IndicatorSeparator: () => null }}
                                required
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                    boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                    '&:hover': {
                                      borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                    },
                                    borderRadius: '10px',
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                    transition: '250ms',
                                    width: '35px',
                                    height: '35px',
                                  }),
                                }}
                                isMulti
                                isClearable
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Blood Group <span className="login-danger">*</span>
                              </label>
                              <input
                                name="blood_group"
                                className="form-control"
                                value={formData.blood_group}
                                onChange={handleChange}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Medical License Number 
                              </label>
                              <input
                                name="license_number"
                                value={formData.license_number}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                              Specialization
                              </label>
                              <CreatableSelect
                                name="specialities"
                                menuPosition={'fixed'}
                                value={selectedSpeciality}
                                onChange={handleSpecialityChange}
                                options={specialityOptions}
                                menuPortalTarget={document.body}
                                styles={{ /* Custom Styles */ }}
                                id="search-commodity"
                                components={{ IndicatorSeparator: () => null }}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                    boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                    '&:hover': {
                                      borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                    },
                                    borderRadius: '10px',
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                    transition: '250ms',
                                    width: '35px',
                                    height: '35px',
                                  }),
                                }}
                                isMulti
                                isClearable
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group local-forms">
                              <label>
                                Degree 
                              </label>
                              <input
                                name="degree"
                                value={formData.degree}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="row" style={{ marginBottom: '20px' }}>
                            <div className="form-group theme-set col-12 col-md-6 col-xl-6">
                              <label
                                className="local-top"
                                style={{
                                  fontSize: '12px',
                                  color: '#333448',
                                  fontWeight: 500,
                                  background: '#fff',
                                  marginTop: 0,
                                  marginBottom: 0,
                                  padding: '0px 5px',
                                }}
                              >
                                Doctor Picture
                              </label>
                              <input
                                className="form-control"
                                type="file"
                                name="picture"
                                accept="image/*"
                                id="file"
                                onChange={handleFileChange}
                              />
                              <span className="form-text text-muted" style={{
                                fontSize: '12px', marginBottom: '14px'
                              }}>
                                Recommended image size is 40px x 40px
                              </span>
                            </div>

                            <div className="form-group theme-set col-12 col-md-6 col-xl-6">
                              <label
                                className="local-top"
                                style={{
                                  fontSize: '12px',
                                  color: '#333448',
                                  fontWeight: 500,
                                  background: '#fff',
                                  marginTop: 0,
                                  marginBottom: 0,
                                  padding: '0px 5px',
                                }}
                              >
                                Doctor Signature
                              </label>
                              <input
                                className="form-control"
                                type="file"
                                name="signature"
                                accept="image/*"
                                id="file"
                                onChange={handleFileChange}
                              />
                              <span className="form-text text-muted" style={{
                                fontSize: '12px', marginBottom: '14px'
                              }}>
                                Recommended image size is 40px x 40px
                              </span>
                            </div>
                          </div>

                          <div className="col-12 col-sm-12">
                            <div className="form-group local-forms">
                              <label>
                                Address 
                              </label>
                              <textarea
                                className="form-control"
                                rows={3}
                                cols={30}
                                name="address"
                                value={formData.address}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Postal Code 
                              </label>
                              <input
                                name="pin_code"
                                value={formData.pin_code}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                City 
                              </label>
                              <Select
                                name="city"
                                menuPosition={'fixed'}
                                value={selectedCity}
                                // onChange={handleCityChange}
                                // options={cityOptions}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                id="search-commodity"
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                    boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                    '&:hover': {
                                      borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                    },
                                    borderRadius: '10px',
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                    transition: '250ms',
                                    width: '35px',
                                    height: '35px',
                                  }),
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Country 
                              </label>
                              <Select
                                name="country"
                                menuPosition={'fixed'}
                                value={selectedCountry}
                                // onChange={handleCountryChange}
                                // options={countryOptions}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                id="search-commodity"
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                    boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                    '&:hover': {
                                      borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                    },
                                    borderRadius: '10px',
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                    transition: '250ms',
                                    width: '35px',
                                    height: '35px',
                                  }),
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                State/Province
                              </label>
                              <Select
                                name="state"
                                menuPosition={'fixed'}
                                value={selectedState}
                                // onChange={handleStateChange}
                                // options={stateOptions}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                id="search-commodity"
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                    boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                    '&:hover': {
                                      borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                    },
                                    borderRadius: '10px',
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                    transition: '250ms',
                                    width: '35px',
                                    height: '35px',
                                  }),
                                }}

                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Aadhaar Number
                              </label>
                              <input
                                name="aadhar_number"
                                value={formData.aadhar_number}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                              <label>
                                Pan Number
                              </label>
                              <input
                                name="pan_number"
                                value={formData.pan_number}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Account Name
                              </label>
                              <input
                                name="account_holder_name"
                                value={formData.account_holder_name}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Account Number
                              </label>
                              <input
                                name="account_number"
                                value={formData.account_number}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                IFSC Code
                              </label>
                              <input
                                name="ifsc_code"
                                value={formData.ifsc_code}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Bank Branch
                              </label>
                              <input
                                name="bank_branch"
                                value={formData.bank_branch}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Facebook ID
                              </label>
                              <input
                                name="facebook_id"
                                value={formData.facebook_id}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Instagram ID
                              </label>
                              <input
                                name="instagram_id"
                                value={formData.instagram_id}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                Twitter ID
                              </label>
                              <input
                                name="twitter_id"
                                value={formData.twitter_id}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group local-forms">
                              <label>
                                LinkedIn ID
                              </label>
                              <input
                                name="linkedin_id"
                                value={formData.linkedin_id}
                                onChange={handleChange}
                                className="form-control"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group select-gender">
                              <label className="gen-label">
                                Status <span className="login-danger">*</span>
                              </label>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="status"
                                    value="Active"
                                    className="form-check-input"
                                    checked={formData.status === 'Active'}
                                    onChange={handleChange}
                                  />
                                  Active
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    name="status"
                                    value="InActive"
                                    className="form-check-input"
                                    checked={formData.status === 'InActive'}
                                    onChange={handleChange}
                                  />
                                  InActive
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="doctor-submit text-end">
                              <button
                                type="submit"
                                className="btn btn-primary submit-form me-2"
                              >
                                Update
                              </button>
                              <button
                                type="reset"
                                className="btn btn-primary cancel-form"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Notifications - Box */}
        </div>
        {/* Popup box */}
        <div id="popupMessageModal" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
              {isSuccessful ? (
                  <img src={SuccessIcon} width={32} alt="Success" />
                ) : (
                  <img src={DeleteIcon} width={32} alt="Error" />
                )}
                <h3>{popupMessage}</h3> 
                <div className="m-t-20">
                {isSuccessful ? (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#009F6F' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#009F6F'}
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#dc3545' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#CA2127'}
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Popup box */}
      </>
    </div>
  );
};

export default EditDoctor;
