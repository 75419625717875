import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const ECGGraph = () => {
    // Replace this with your actual ECG data points
    const myEcgData = [
    ]

    const [ecgData, setEcgData] = useState(myEcgData.slice(0, 200)); // Initialize with the first 200 points
    const [labels, setLabels] = useState(myEcgData.map((_, index) => index).slice(0, 200)); // Generate labels for the first 200 points
    const [windowSize, setWindowSize] = useState(200); // Size of the window
    const [currentIndex, setCurrentIndex] = useState(0); // Keep track of the current index for animation

    useEffect(() => {
        const updateChart = () => {
            // Check if there are more points to display
            if (currentIndex < myEcgData.length) {
                setEcgData(prevData => {
                    // Shift data left and add the new point to the end
                    return [...prevData.slice(1), myEcgData[currentIndex]];
                });

                setLabels(prevLabels => {
                    // Shift labels left and add the new index to the end
                    return [...prevLabels.slice(1), currentIndex + 1];
                });

                // Move to the next index
                setCurrentIndex(currentIndex + 1);
            }
        };

        // Set interval for updating the chart
        const animationInterval = setInterval(updateChart, 100); // Update every 300 ms

        return () => {
            clearInterval(animationInterval); // Cleanup on unmount
        };
    }, [currentIndex]); // Add currentIndex as a dependency

    // Data for the chart
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'ECG Data',
                data: ecgData,
                borderColor: 'rgba(36, 85, 174, 1)',
                borderWidth: 2,
                fill: false,
                pointRadius: 0, // Hide the points on the line graph
            },
        ],
    };

    // Chart options
    const options = {
        animation: {
            duration: 0, // Disable animation for smoother experience
        },
        scales: {
            x: {
                title: {
                    display: false,
                },
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)', // X-axis grid color
                    lineWidth: 1,
                },
                ticks: {
                    display: false, // Show x-axis ticks
                    autoSkip: true,
                    maxTicksLimit: 20, // Increase the limit to show more ticks
                    stepSize: 1, // Adjust step size for better spacing
                },
            },
            y: {
                title: {
                    display: false,
                },
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.1)', // Y-axis grid color
                    lineWidth: 1,
                },
                ticks: {
                    display: false, // Show y-axis ticks
                    max: 20, // Set max value for y-axis
                    min: -10, // Set min value for y-axis
                    stepSize: 5, // Set step size for fixed intervals
                },
            },
        },
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
        },
    };

    return (
        <div style={{ background: '#f9fafb', padding: '10px', borderRadius: '8px', textAlign: 'center', minHeight: '100px' }}>
            <Line data={data} options={options} />
        </div>
    );
};

export default ECGGraph;
