import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const BloodPressureChart = ({patient}) => {
  const bpChartRef = useRef(null);
  const [chart, setChart] = React.useState(null);
  const windowSize = 50;

  const createChart = () => {
    const ctx = bpChartRef.current.getContext('2d');
    let chartData={labels:[], data1: [], data2: []};
    if(patient.vitals.vitals.bp && patient.vitals.vitals.bp.length>0) {
      for (let i = ((patient.vitals.vitals.bp.length>windowSize)?patient.vitals.vitals.bp.length-windowSize:0); i < patient.vitals.vitals.bp.length; i++) {
        chartData.labels.push(patient.vitals.vitals.bp[i].t);
        chartData.data1.push(patient.vitals.vitals.bp[i].sp);
        chartData.data2.push(patient.vitals.vitals.bp[i].dp);
      }
    }
    const bpChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: chartData.labels,
        datasets: [{
          label: "Systolic",
          data: chartData.data1,
          borderColor: '#38CB89',
          backgroundColor:'rgba(255, 54, 103, 0.2)',
          tension: 0.4,
          borderWidth: 4, // Adjust the line width here
          pointRadius: 0, // Hide the circle at the end
          fill: false,
        },{
          label: "Diastolic",
          data: chartData.data2,
          borderColor: '#367FFF',
          backgroundColor: 'rgba(54, 103, 255, 0.2)',
          tension: 0.4,
          borderWidth: 4, // Adjust the line width here
          pointRadius: 0, // Hide the circle at the end
          fill: false,
        }],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: true,
          },
        },
        animation: {
          duration: 200, // Disable animation
        },
      },
    });
    const meta = bpChart.getDatasetMeta(0);
    const lastPoint = meta.data[meta.data.length - 1];
    ctx.save();
    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
    ctx.shadowBlur = 10;
    ctx.fillStyle = '#FF3667';
    ctx.beginPath();
    ctx.arc(lastPoint?.x, lastPoint?.y, 25, 0, 2 * Math.PI);
    ctx.fill();
    ctx.restore();
    setChart(bpChart);
  };

  const updateChart = () => {
    let chartData={labels:[], data1: [], data2: []};
    if(patient.vitals.vitals.bp && patient.vitals.vitals.bp.length>0) {
      for (let i = ((patient.vitals.vitals.bp.length>windowSize)?patient.vitals.vitals.bp.length-windowSize:0); i < patient.vitals.vitals.bp.length; i++) {
        chartData.labels.push(patient.vitals.vitals.bp[i].t);
        chartData.data1.push(patient.vitals.vitals.bp[i].sp);
        chartData.data2.push(patient.vitals.vitals.bp[i].dp);
      }
    }
    chart.data.labels=chartData.labels;
    chart.data.datasets[0].data=chartData.data1;
    chart.data.datasets[1].data=chartData.data2;
    if (chart.data.labels.length >= windowSize) {
      chart.data.labels.splice(0,(chartData.labels.length - windowSize));
      chart.data.datasets[0].data.splice(0,(chartData.labels.length - windowSize));
      chart.data.datasets[1].data.splice(0,(chartData.labels.length - windowSize));
    }
    chart.update();
  };

  useEffect(() => {
    if(patient && bpChartRef.current) {
      createChart();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    }
  }, []);

  useEffect(() => {
    if(chart) {
      updateChart();
    }
  }, [patient.vitals.vitals.bp])

  return (
    <div>
      <canvas id="bp-chart" ref={bpChartRef}></canvas>
    </div>
  );
};

export default BloodPressureChart;
