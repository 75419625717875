export const ENDPOINT = {
    login: "/api/v2/admin/login",
    forgotPassword: "/api/v2/admin/change_password",
    resetPassword: "/api/v2/admin/reset_password",
    sendResetPassword: "/api/v2/admin/reset_password_request",
    verifyEmail: "/api/v2/admin/verify_email",
    sendVerifyEmail: "/api/v2/admin/send_verification_email",
    sendVerifyMobile: "/api/v2/admin/send_sms",
    verifyMobileOtp: "/api/v2/admin/verify_otp",
    verifyOtp: "/api/v2/admin/verify_otp_for_password_reset",

    // User apis
    addUser: "/api/v2/user/",
    getUser: "/api/v2/user/",
    editUser: "/api/v2/user/{user_id}",
    getUserProfile: "/api/v2/user/{user_id}",
    deleteUser: "/api/v2/user/{user_id}",
    updateContact: "/api/v2/user/update_contact/",
    // Clients apis
    addClient: "/api/v2/clients/df_userid/{df_userid}/add_client",
    getClients: "/api/v2/clients/get_all_clients",
    editClient: "/api/v2/clients/{client_id}/update",
    getClientProfile: "/api/v2/clients/{client_id}/get_client",
    // Clinics apis
    addClinic: "/api/v2/clients/{client_id}/add_clinic",
    getClinics: "/api/v2/clients/{client_id}/get_all_clinics",
    editClinic: "/api/v2/clients/{client_id}/clinics/{clinic_id}/update",
    getClinicProfile: "/api/v2/clients/{client_id}/clinics/{clinic_id}",
    getAllPatients: "/api/v2/clinics/{clinic_id}/get_all_patients",
    getAssignedPatients: "/api/v2/clinics/{clinic_id}/get_all_assigned_patients",
    // doctors apis
    addDoctor: "/api/v2/clinics/{clinic_id}/create_doctor",
    getDoctors: "/api/v2/clinics/{clinic_id}/get_all_doctors",
    editDoctor: "/api/v2/clinics/{clinic_id}/{doctor_id}/update",
    getDoctorProfile: "/api/v2/clinics/{clinic_id}/doctors/{doctor_id}/get_doctor",
    // Nurse apis
    addNurse: "/api/v2/clinics/{clinic_id}/add_nurse",
    getNurses: "/api/v2/clinics/{clinic_id}/list_nurses",
    editNurse: "/api/v2/clinics/{clinic_id}/update_nurse/{nurse_id}",
    getNurseProfile: "/api/v2/clinics/{clinic_id}/nurses/{nurse_id}",
    // Patients apis
    addPatient: "/api/v2/clinics/{clinic_id}/add_patient",
    getPatients: "/api/v2/clinics/{clinic_id}/list_patients",
    editPatient: "/api/v2/clinics/{clinic_id}/update_patient/{patient_id}",
    getPatientProfile: "/api/v2/clinics/{clinic_id}/patients/{patient_id}/get_patient",
    assignPatient: "/api/v2/clinics/{clinic_id}/assign_patient/{patient_id}",
    listAssignPatients: "/api/v2/clinics{clinic_id}/list_assigned_patients",
    unAssignPatient: "/api/v2/clinics/{clinic_id}/device/{device_mac_id}/unassign_patient/{patient_id}",
    getPatientVitals: "wss://api.dev.deepfacts.io/streaming_data/v2/stream/vitals/dashboard?pid={patient_id}",
    // Devices apis
    addDevice: "/api/v2/devices/df_userid/{df_userid}/add_device",
    getDevices: "/api/v2/devices/list_devices",
    editDevice: "/api/v2/devices/get_device/{device_id}",
    getDeviceProfile: "/api/v2/devices/get_device/{device_id}",
    getDevicesByStatus: "/api/v2/devices/status/{device_status}",
    // Remote Patient Monitoring apis
    /*getRpmDetails: "/api/v2/clients/{client_id}/get_rpm_details",
    // User Activity apis
    getUserActivity: "/api/v2/clients/{client_id}/get_user_activity",
    // Reports apis
    getExpensesReport: "/api/v2/clients/{client_id}/get_expenses_report",
    addExpenses: "/api/v2/clients/{client_id}/add_expenses",
    getInvoiceReport: "/api/v2/clients/{client_id}/get_invoice_report",
    // Invoices apis
    getInvoiceDetails: "/api/v2/clients/{client_id}/get_invoice_details",
    addInvoice: "/api/v2/clients/{client_id}/add_invoice",
    getInvoice: "/api/v2/clients/{client_id}/get_invoice",
    editInvoice: "/api/v2/clients/{client_id}/invoices/{invoice_id}/update",
    // Notifications apis
    getNotifications: "/api/v2/clients/{client_id}/get_notifications",*/

}