import React from 'react';

const PopupModal = ({ show, onClose, message, type }) => {
    if (!show) return null; // If not showing, don't render anything

    return (
        <div
            className="modal fade show"
            style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className={`modal-content ${type === 'success' ? 'modal-success' : 'modal-error'}`}>
                    <div className="modal-header">
                        <h4 className="modal-title" id="modalLabel">
                            {type === 'success' ? 'Success' : 'Error'}
                        </h4>
                        <button
                            type="button"
                            className="close"
                            onClick={onClose}  // Handle the close event properly
                            style={{ fontSize: '32px', border: 'none', background: 'none', color: '#000' }}
                        >
                            &times;
                        </button>
                    </div>
                    <div
                        className={`modal-body text-center ${
                            type === 'success' ? 'alert alert-success' : 'alert alert-danger'
                        }`}
                        style={{ margin: '30px' }}
                    >
                        {message}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopupModal;
