import React, { useEffect, useState } from 'react';
import CardsGrid from './patient-info-card';
import { clinicService } from "../../../services/clinic/clinicService";
import { patientService } from "../../../services/patient/patientService";
import { ENDPOINT } from "../../../config/endpoint";
import { doctorService } from "../../../services/doctor/doctorService";
import Patient_Dashboard from "../Patient_Dashboard/Patient_Dashboard";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import getClinicId from '../../../services/getClinicId';
import PopupModal from '../../Popups/clinicPopupModal';

const ClinicalHealthCard = ({ user, authenticated, patient_id }) => {
    const [showVitals, setShowVitals] = useState(false);
    const [socket, setSocket] = useState([]);
    const [patients, setPatients] = useState([]);
    const [processPatient, setProcessPatient] = useState(false);
    const ws = [];
    const [selectedPatient, setSelectedPatient] = useState(null);
    const { clinic_id, error } = getClinicId();  // Fetch clinic_id from Redux
    const [showErrorPopup, setShowErrorPopup] = useState(false); // State for controlling popup visibility
    const [errorMessage, setErrorMessage] = useState(''); // State for storing error message

    useEffect(() => {
        if (!clinic_id) {
            setErrorMessage(error);
            setShowErrorPopup(true);
            return;
        }

        getPatients(); // fetch patients when clinic_id is available

        return () => {
            if (ws.length > 0) {
                for (let i = 0; i < socket.length; i++) {
                    ws[i].close();
                }
            }
        };
    }, [clinic_id]);

    const openSocket = async (patient, i) => {
        const url = ENDPOINT.getPatientVitals.replace('{patient_id}', patient.patient_id);
        ws[i] = new WebSocket(url);

        ws[i].onopen = () => {
            console.log('WebSocket connection opened');
        };

        ws[i].onmessage = (event) => {
            const newData = JSON.parse(event.data);
            console.log(newData);
            patients[i].vitals = newData;
            setPatients([...patients]);
            if (selectedPatient && selectedPatient.patient_id === patient.patient_id) {
                setSelectedPatient(patients[i]);
            }
        };

        ws[i].onclose = () => {
            console.log('WebSocket connection closed');
        };

        let existingSockets = [...socket];
        existingSockets.push(ws[i]);
        setSocket([...existingSockets]);
    }

    const processPatientProfile = async (patient, i) => {
        const patientProfile = await patientService.getPatientProfile(patient.patient_id, clinic_id);
        if (patientProfile) {
            patients[i].patientDetails = patientProfile;
            setPatients([...patients]);
        }
    }

    const processDoctor = async (patient, i) => {
        patients[i].doctorDetails = await doctorService.getDoctorProfile(patient.doctor_id, clinic_id);
        setPatients([...patients]);
    }

    const processPatients = async () => {
        for (let i = 0; i < patients.length; i++) {
            await openSocket(patients[i], i);
        }
        setProcessPatient(false);
        setShowVitals(true);
    }

    useEffect(() => {
        if (processPatient) {
            processPatients();
        }
    }, [processPatient]);

    const getPatients = async () => {
        try {
            const assignPatients = await clinicService.getAssignedPatients(clinic_id);
            setPatients(assignPatients);
            setProcessPatient(true);
        } catch (error) {
            console.error("Error fetching assigned patients:", error);
        }
    }

    const selectPatient = (patient) => {
        setSelectedPatient(patient);
    }

    const unselectPatient = () => {
        setSelectedPatient(null);
    }

    // Function to handle closing the error popup
    const handleCloseErrorPopup = () => {
        setShowErrorPopup(false);
    };

    return (
        <>
            <PopupModal
                show={showErrorPopup}
                onClose={handleCloseErrorPopup}
                title="Error"
                message={errorMessage}
                type="danger" 
            />
            {(showVitals && !selectedPatient) &&
                <div className="page-wrapper">
                    <div className="content">
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/admin-dashboard">Dashboard</Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right" />
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Remote Patient Monitoring</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                            <div style={{ width: '100%', height: '100%', padding: '0px' }}>
                                <CardsGrid patientCards={patients} selectPatient={selectPatient} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(selectedPatient) &&
                <Patient_Dashboard patient={selectedPatient} unselectPatient={unselectPatient} user={user} />}
        </>
    );
};

export default ClinicalHealthCard;
