import apiClient from '../apiservice';
import getClinicId from '../getClinicId';
import { store } from "../../utils/redux/store";
const clinicApi = {
  createClinic: async (clinicData, selectedClientId = null) => {
    try {
      const clientId = store.getState().auth.client_id || selectedClientId; 
      const response = await apiClient.post(`/clients/${clientId}/add_clinic`, clinicData);
      return response.data;
    } catch (error) {
      console.error('Error creating clinic:', error);
      throw error;
    }
  },

  getClinics: async (selectedClientId = null) => {
    try {
      const clientId = store.getState().auth.client_id || selectedClientId; 
      const response = await apiClient.get(`/clients/${clientId}/get_all_clinics`);
      return response.data;
    } catch (error) {
      console.error('Error fetching clinics:', error);
      throw error;
    }
  },
 
  readClinic: async (clinicId, selectedClientId = null) => {
    try {
      const clientId = store.getState().auth.client_id || selectedClientId; 
      const response = await apiClient.get(`/clients/${clientId}/clinics/${clinicId}/get_clinic`);
      return response.data;
    } catch (error) {
      console.error('Error reading clinic:', error);
      throw error;
    }
  },

  updateClinic: async (clinicId, clinicData, selectedClientId = null) => {
    try {
      const clientId = store.getState().auth.client_id || selectedClientId; 
      const response = await apiClient.put(`/clients/${clientId}/clinics/${clinicId}/update`, clinicData);
      return response.data;
    } catch (error) {
      console.error('Error updating clinic:', error);
      throw error;
    }
  },
};

export default clinicApi;
