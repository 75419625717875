import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { verifyOtp, sendVerificationEmail, verifyEmailOtp } from './services/userService';
import { login02 } from './components/imagepath';
import proRithmLogo from "../src/assets/img/proRithm.png";

const Modal = ({ message, type, onClose }) => (
    <div
        className="modal fade show"
        style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
    >
        <div className="modal-dialog modal-dialog-centered">
            <div className={`modal-content ${type === 'success' ? 'modal-success' : 'modal-error'}`}>
                <div className="modal-header">
                    <h4 className="modal-title" id="modalLabel">{type === 'success' ? 'Success' : 'Error'}</h4>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={onClose}
                        style={{ fontSize: '32px', border: 'none', background: 'none', color: '#000' }}
                    >
                        &times;
                    </button>
                </div>
                <div className={`modal-body text-center ${type === 'success' ? 'alert alert-success' : 'alert alert-danger'}`} style={{ margin: '30px' }}>
                    {message}
                </div>
            </div>
        </div>
    </div>
);

const VerifyOtp = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { clientId, clinicId, mobileNumber, email, mobileVerified, emailVerified, isMobileVerification } = location.state || {};

    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalType, setModalType] = useState('error');
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        // If mobile is verified and email is not, send verification email
        if (mobileVerified && !emailVerified) {
            sendVerificationEmail(email);
        }
    }, [mobileVerified, emailVerified, email]);

    // Format the mobile number with placeholders
    const formatMobileNumber = (number) => {
        if (!number || number.length < 10) return number;
        return (
            <>
                <span style={{ marginRight: '4px' }}>+91</span>
                <span style={{ marginRight: '0px' }}>{number.slice(0, 5)}</span>
                <span style={{ marginLeft: '0px', marginRight: '0px' }}>XXXX</span>
                <span>{number.slice(-1)}</span>
            </>
        );
    };

    // Format the email address for display
    const formatEmail = (email) => {
        if (!email || email.length < 5) return email;
        const [name, domain] = email.split('@');
        const hiddenName = name.length > 2 ? `${name.slice(0, 2)}***` : `${name.slice(0, 1)}***`;
        return `${hiddenName}@${domain}`;
    };

    const formattedMobileNumber = formatMobileNumber(mobileNumber);
    const formattedEmail = formatEmail(email);

    const handleOtpChange = (event) => {
        setOtp(event.target.value);
    };

    const handleVerifyClick = async () => {
        if (!otp) {
            setError('Please enter the OTP.');
            return;
        }

        setLoading(true);
        setError('');

        try {
            let response;
            if (isMobileVerification) {
                response = await verifyOtp({ otp, username: mobileNumber });
            } else {
                response = await verifyEmailOtp({ token: otp });
            }

            if (response && response.status === 200) {
                setModalMessage(response.message);
                setModalType('success');
                setIsSuccess(true); // Mark as success
                setModalVisible(true);

                if (response.message.toLowerCase().includes("success")) {
                    setTimeout(() => navigate('/'), 2000);
                }
            } else {
                throw new Error(response?.message || 'Failed to verify OTP. Please try again.');
            }
        } catch (error) {
            console.error('Verification error:', error);
            setModalMessage(error.message);
            setModalType('error');
            setIsSuccess(false); // Mark as failure
            setModalVisible(true);
        } finally {
            setLoading(false);
        }
    };

    const handleModalClose = () => {
        setModalVisible(false);
        if (isSuccess) {
            navigate('/dashboard');
        }
    };

    return (
        <div>
            <div className="main-wrapper login-body">
                <div className="container-fluid px-0">
                    <div className="row">
                        <div className="col-lg-6 login-wrap">
                            <div className="login-sec">
                                <div className="log-img">
                                    <img className="img-fluid" src={login02} alt="Logo" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 login-wrap-bg">
                            <div className="login-wrapper">
                                <div className="loginbox">
                                    <div className="login-right">
                                        <div className="login-right-wrap">
                                            <div className="account-logo">
                                                <Link to="/">
                                                    <img src={proRithmLogo} width={165} height={45} alt="#" />
                                                </Link>
                                            </div>
                                            <h2 style={{ marginBottom: '5px' }}>Verify OTP</h2>
                                            <p style={{
                                                fontSize: '15px',
                                                fontWeight: '500',
                                                color: 'rgba(51, 52, 72, 0.5)',
                                                marginTop: '0',
                                                marginBottom: '45px'
                                            }}>
                                                Enter the OTP sent to <span style={{ color: '#3942B2' }}>{isMobileVerification ? formattedMobileNumber : formattedEmail}</span>
                                            </p>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter OTP"
                                                    value={otp}
                                                    onChange={handleOtpChange}
                                                    minLength={6}
                                                    maxLength={6}
                                                />
                                            </div>
                                            {error && <p style={{ color: 'red' }}>{error}</p>}
                                            <button
                                                className="btn btn-primary w-100 btn-lg login-btn"
                                                onClick={handleVerifyClick}
                                                disabled={loading}
                                            >
                                                {loading ? 'Verifying...' : 'Verify'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modalVisible && <Modal message={modalMessage} type={modalType} onClose={handleModalClose} />}
        </div>
    );
};

export default VerifyOtp;
