import React, { useEffect, useState } from 'react';
import { Table } from "antd";
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import patientApi from '../../services/ClientServices/PatientRoutes';
import { onShowSizeChange, itemRender } from '../Pagination'
import {
  blogimg10, imagesend, pdficon, pdficon3, pdficon4, plusicon, refreshicon, searchnormal, blogimg12,
  blogimg2, blogimg4, blogimg6, blogimg8, error02, dangericon
} from '../imagepath';
import StatusButton from '../../statusbutton';


const RpmDetails = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [error, setError] = useState(null);

  const fetchAssignedPatients = async () => {
    try {
      const response = await patientApi.getAssignedPatients();
      setDataSource(response.assign_patients || []); // Ensure data is an array
      setError(null);
    } catch (error) {
      console.error('Error fetching assigned patients:', error);
      setDataSource([]); // Set empty array on error
    }
  };
  useEffect(() => {
    fetchAssignedPatients();
  }, []);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "Clinic Name",
      dataIndex: "clinic_name",
      sorter: (a, b) => a.clinic_name.localeCompare(b.clinic_name),
    },
    {
      title: "Doctor Name",
      dataIndex: "doctor_name",
      sorter: (a, b) => a.doctor_name.localeCompare(b.doctor_name),
    },
    {
      title: "Patient Name",
      dataIndex: "patient_name",
      sorter: (a, b) => a.patient_name.localeCompare(b.patient_name),
    },
    {
      title: "Block",
      dataIndex: "block",
      sorter: (a, b) => a.block.localeCompare(b.block),
    },
    {
      title: "Ward",
      dataIndex: "ward",
      sorter: (a, b) => a.ward.localeCompare(b.ward),
    },
    {
      title: "Bed",
      dataIndex: "bed",
      sorter: (a, b) => a.bed - b.bed,
    },
    {
      title: "Floor",
      dataIndex: "floor",
      sorter: (a, b) => a.floor - b.floor,
    },
    {
      title: "Room",
      dataIndex: "room",
      sorter: (a, b) => a.room - b.room,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => (
        <StatusButton status={status} onPatientStatusClick={() => console.log(`Status: ${status}`)} />
      ),
    },
    // {
    //   title: "",
    //   dataIndex: "actions",
    //   render: (text, record) => (
    //     <div className="text-end">
    //       <button onClick={() => handleEditClick(record.id)} className="btn btn-primary me-2">Edit</button>
    //       <button onClick={() => handleViewClick(record.id)} className="btn btn-secondary">View</button>
    //     </div>
    //   ),
    // },
  ];


  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Patient Monitoring</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Assigned Patient List</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {error ? (
            <div className="main-wrapper error-wrapper">
              <div className="error-box">
                <img className="img-fluid" src={error02} alt="Logo" />
                <h3>
                  <img
                    className="img-fluid mb-0"
                    src={dangericon}
                    alt="Logo"
                  />{" "}
                  Service Unavailable
                </h3>
                <p>Sorry, an error has occured, and we're working to fix the problem.</p>
                <Link to="/" className="btn btn-primary go-home">
                  Back to Home
                </Link>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    {/* Table Header */}
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Assigned Patient List</h3>
                            <div className="doctor-search-blk">
                              <div className="top-nav-search table-search-blk">
                                <form>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search here"
                                  />
                                  <Link className="btn">
                                    <img
                                      src={searchnormal}
                                      alt="#"
                                    />
                                  </Link>
                                </form>
                              </div>
                              <div className="add-group">
                                <Link
                                  to="/assign-patient"
                                  className="btn btn-primary add-pluss ms-2"
                                >
                                  <img src={plusicon} alt="#" />
                                </Link>
                                <Link
                                  to="#"
                                  onClick={fetchAssignedPatients}
                                  className="btn btn-primary doctor-refresh ms-2"
                                >
                                  <img src={refreshicon} alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          <Link to="#" className=" me-2">
                            <img src={pdficon} alt="#" />
                          </Link>
                          <Link to="#" className=" me-2">
                            <img src={pdficon3} alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={pdficon4} alt="#" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* /Table Header */}
                    <div className="table-responsive doctor-list">
                      <Table
                        pagination={{
                          total: dataSource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={dataSource}
                        rowSelection={rowSelection}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RpmDetails;
