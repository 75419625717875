import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const HeartRateChart = ({patient}) => {
  const chartRef = useRef(null);
  const windowSize = 50;

  const [chart, setChart] = React.useState(null);

  const createChart = () => {
    let chartData={labels:[], data: []};
    if(patient.vitals.vitals.hr && patient.vitals.vitals.hr.length>0) {
      for (let i = ((patient.vitals.vitals.hr.length>windowSize)?patient.vitals.vitals.hr.length-windowSize:0); i < patient.vitals.vitals.hr.length; i++) {
        chartData.labels.push(patient.vitals.vitals.hr[i].t);
        chartData.data.push(patient.vitals.vitals.hr[i].v);
      }
    }
    const ctx = chartRef.current.getContext('2d');
    const hrChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: chartData.labels,
        datasets: [{
          label: 'Heart Rate',
          data: chartData.data,
          borderColor: '#FF3667',
          backgroundColor: 'rgba(255, 54, 103, 0.2)',
          tension: 0.4,
          pointRadius: 0,
          fill: false,
        }],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: true,
          },
        },
        animation: {
          duration: 200,
        },
      },
    });
    const meta = hrChart.getDatasetMeta(0);
    const lastPoint = meta.data[meta.data.length - 1];
    ctx.save();
    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
    ctx.shadowBlur = 10;
    ctx.fillStyle = '#FF3667';
    ctx.beginPath();
    ctx.arc(lastPoint.x, lastPoint.y, 25, 0, 2 * Math.PI);
    ctx.fill();
    ctx.restore();
    setChart(hrChart);
  };

  const updateChart = () => {
    let chartData={labels:[], data: []};
    if(patient.vitals.vitals.hr && patient.vitals.vitals.hr.length>0) {
      for (let i = ((patient.vitals.vitals.hr.length>windowSize)?patient.vitals.vitals.hr.length-windowSize:0); i < patient.vitals.vitals.hr.length; i++) {
        chartData.labels.push(patient.vitals.vitals.hr[i].t);
        chartData.data.push(patient.vitals.vitals.hr[i].v);
      }
    }
    chart.data.labels=chartData.labels;
    chart.data.datasets[0].data=chartData.data;
    if (chart.data.labels.length >= windowSize) {
      chart.data.labels.splice(0,(chartData.labels.length - windowSize));
      chart.data.datasets[0].data.splice(0,(chartData.labels.length - windowSize));
    }
    chart.update();
  };

  useEffect(() => {
    if (patient) {
      createChart();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    }
  }, []);

  useEffect(() => {
    if (chart) {
      updateChart();
    }
  }, [patient.vitals.vitals.hr]);

  return <canvas id="heart-rate" width={695} height={180} style={{ display: 'block', boxSizing: 'border-box', height: '180px', width: '695px', marginTop: '20px', }} ref={chartRef}></canvas>;
};

export default HeartRateChart;
