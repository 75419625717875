/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import { Link, useNavigate } from 'react-router-dom';
import moment from "moment";
import { Modal } from 'bootstrap';
import doctorApi from "../../services/ClientServices/DoctorRoutes";
import patientApi from "../../services/ClientServices/PatientRoutes";
import usePostalCode from "../../hooks/usePostalCode";
import SuccessIcon from '../../assets/img/alert-success.256x256.png';
import { refreshicon } from "../imagepath";
import DeleteIcon from "../../assets/img/delete.png";
import { useSelector } from 'react-redux';

const AddPatients = () => {
  const [popupMessage, setPopupMessage] = useState('');
  const [doctors, setDoctors] = useState([]);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();
  const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);
  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(null);
  const [selectedRelation, setSelectedRelation] = useState(null);
  const [isAgeInputVisible, setIsAgeInputVisible] = useState(false); // State to toggle age input visibility
  const userData = useSelector((state) => state.auth.userData);

  const loadFile = (event) => {
    // Handle file loading logic here
  };

  const [formData, setFormData] = useState({
    address: '',
    pin_code: '',
    city: '',
    state: '',
    country: '',
    doctor_id: '',
    first_name: '',
    last_name: '',
    gender: '',
    date_of_birth: '',
    age: '',
    height: '',
    weight: '',
    email: '',
    bmi: '',
    bmi_status: '',
    blood_group: '',
    mobile: '',
    secondary_mobile: '',
    relation: '',
    symptoms: '',
    patient_uid: '',
    status: '',
  });


  const relationOptions = [
    { value: "self", label: "Self" },
    { value: "mother", label: "Mother" },
    { value: "father", label: "Father" },
    { value: "spouse", label: "Spouse" },
    { value: "son", label: "Son" },
    { value: "daughter", label: "Daughter" },
    { value: "other", label: "Other" },
  ];

  const handleRelationChange = (selectedOption) => {
    setSelectedRelation(selectedOption);
    setFormData({
      ...formData,
      relation: selectedOption ? selectedOption.value : '',
    });
  };

  const handleBloodGroupChange = (selectedOption) => {
    setSelectedBloodGroup(selectedOption);
    setFormData({
      ...formData,
      blood_group: selectedOption ? selectedOption.value : '',
    });
  };

  const handleDateOfBirthChange = (date) => {
    if (date) {
      const formattedDate = date.format('YYYY-MM-DD');
      setSelectedDateOfBirth(date);

      // Calculate the age
      const currentDate = moment();
      const birthDate = moment(formattedDate);
      const age = currentDate.diff(birthDate, 'years');

      setFormData({
        ...formData,
        date_of_birth: formattedDate,
        age: age, // Set calculated age in form data
      });
      setIsAgeInputVisible(false);
    } else {
      setSelectedDateOfBirth(null);
      setFormData({
        ...formData,
        date_of_birth: null,
        age: '', // Clear age if no date is selected
      });
    }
  };

  const handleAgeChange = (e) => {
    const ageInput = e.target.value;

    if (ageInput) {
      const age = parseInt(ageInput, 10); // Parse age as an integer
      const currentDate = moment();
      const dob = currentDate.subtract(age, 'years'); // Calculate date of birth
      const formattedDob = dob.format('YYYY-MM-DD');

      // Update both the DatePicker and formData with the calculated DOB
      setSelectedDateOfBirth(dob); // Update date of birth state
      setFormData({
        ...formData,
        age: ageInput, // Update age in form data
        date_of_birth: formattedDob, // Set calculated date of birth in form data
      });
    } else {
      // If age input is empty, clear the fields
      setFormData({
        ...formData,
        age: '',
        date_of_birth: '', // Clear date of birth if age is cleared
      });
      setSelectedDateOfBirth(null);
    }
  };


  const bloodGroupOptions = [
    { value: "A+", label: "A+" },
    { value: "A-", label: "A-" },
    { value: "B+", label: "B+" },
    { value: "B-", label: "B-" },
    { value: "AB+", label: "AB+" },
    { value: "AB-", label: "AB-" },
    { value: "O+", label: "O+" },
    { value: "O-", label: "O-" },
    { value: "None", label: "None" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const fetchDoctors = async () => {
    try {
      if (userData.role !== 'Doctor') {
        const doctorsData = await doctorApi.getDoctors();
        console.log('Fetched Doctors:', doctorsData);

        if (doctorsData && Array.isArray(doctorsData.doctors)) {
          setDoctors(doctorsData.doctors); // Access the correct array
        } else {
          console.error('Doctors data is not an array:', doctorsData);
        }
      }
    } catch (error) {
      console.error('Error fetching doctors:', error);
    }
  };

  useEffect(() => {
    fetchDoctors();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const patientRequest = {};

    const intFields = ['pin_code', 'doctor_id', 'age'];
    const numberFields = ['height', 'weight', 'bmi'];

    // Assign doctor_id based on the role
    const userDoctorId = userData.role === 'Doctor' ? userData.doctor_id : formData.doctor_id;
    const doctorName = userData.role === 'Doctor' ? userData.name : formData.doctor_name;


    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null && formData[key] !== '') {
        if (key === 'symptoms') {
          patientRequest[key] = formData[key].split(',').map(symptom => symptom.trim());
        } else if (intFields.includes(key)) {
          patientRequest[key] = parseInt(formData[key], 10);
        } else if (numberFields.includes(key)) {
          patientRequest[key] = parseFloat(formData[key]);
        } else {
          patientRequest[key] = formData[key];
        }
        console.log(`Key: ${key}, Value: ${patientRequest[key]}`);
      }
    });
    // Override doctor_id and doctor_name for the doctor role
    patientRequest.doctor_id = userDoctorId;
    patientRequest.doctor_name = doctorName;

    try {
      const response = await patientApi.addPatient(patientRequest);

      if (response.status === 409) {
        setPopupMessage(response.message);
        setIsSuccessful(false);
      } else {
        setPopupMessage(response.message);
        setIsSuccessful(true);
      }

      setPopupMessage(response.message);
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    } catch (error) {
      setPopupMessage('Failed to create Patient. Please try again.');
      setIsSuccessful(false);

      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    }
  };

  const handleClose = () => {
    const popupMessageModal = Modal.getInstance(document.getElementById('popupMessageModal'));
    popupMessageModal.hide();
    if (isSuccessful) {
      navigate('/patient-list');
    }
  };

  const doctorOptions = doctors.map((doctor) => ({
    value: doctor.id,
    label: `${doctor.first_name} ${doctor.last_name}`,
  }));

  return (
    <div>
      {/*<Header /> <Sidebar id="menu-item4" id1="menu-items4" activeClassName="add-patient" />*/}
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Patients </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Patient</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="row" style={{ marginBottom: '30px' }}>
                          <div className="doctor-table-blk">
                            <h3>Patients Details</h3>
                            <div className="doctor-search-blk">
                              <div className="top-nav-search table-search-blk">
                              </div>
                              <div className="add-group">
                                <Link to="#" className="btn btn-primary doctor-refresh ms-2"
                                  onClick={fetchDoctors} >
                                  <img src={refreshicon} alt="#" /> </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              First Name <span className="login-danger">*</span>
                            </label>
                            <input
                              name="first_name"
                              value={formData.first_name}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Last Name <span className="login-danger">*</span>
                            </label>
                            <input
                              name="last_name"
                              value={formData.last_name}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Mobile <span className="login-danger">*</span>
                            </label>
                            <input
                              name="mobile"
                              value={formData.mobile}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              maxLength={10}
                              minLength={10}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Secondary  Mobile
                            </label>
                            <input
                              name="secondary_mobile"
                              value={formData.secondary_mobile}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              maxLength={10}
                              minLength={10}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Gender <span className="login-danger">*</span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Male"
                                  className="form-check-input"
                                  checked={formData.gender === 'Male'}
                                  onChange={handleChange}
                                />
                                Male
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Female"
                                  className="form-check-input"
                                  checked={formData.gender === 'Female'}
                                  onChange={handleChange}
                                />
                                Female
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Other"
                                  className="form-check-input"
                                  checked={formData.gender === 'Other'}
                                  onChange={handleChange}
                                />
                                Others
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              Date Of Birth <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              className="form-control datetimepicker"
                              selected={selectedDateOfBirth}
                              onChange={handleDateOfBirthChange}
                              format="DD-MM-YYYY"
                              wrapperClassName="date-picker"
                              style={{
                                borderColor: selectedDateOfBirth ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                              }}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>Age</label>
                            <input
                              name="age"
                              value={formData.age}
                              onChange={handleAgeChange} // Call handleAgeChange on age input
                              className="form-control"
                              type="number" // Change to number type for age input
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4" style={{ display: 'none' }}>
                          <div className="form-group local-forms">
                            <label>Date Of Birth (Calculated)</label>
                            <input
                              name="calculated_date_of_birth"
                              value={formData.date_of_birth} 
                              className="form-control"
                              type="text" 
                              readOnly
                              placeholder="Calculated DOB"
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Relation <span className="login-danger">*</span>
                            </label>
                            <Select
                              value={selectedRelation}
                              onChange={handleRelationChange}
                              options={relationOptions}
                              id="relation-select"
                              menuPortalTarget={document.body}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              required
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Blood Group
                            </label>
                            <Select
                              name="blood_group"
                              value={selectedBloodGroup}
                              onChange={handleBloodGroupChange}
                              options={bloodGroupOptions}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Doctor Name <span className="login-danger">*</span>
                            </label>
                            {userData.role === 'Doctor' ? (
                              // If the logged-in user is a Doctor, display their name as read-only
                              <input
                                type="text"
                                className="form-control"
                                value={userData.name} // Use the name from the token
                                readOnly
                              />
                            ) : (
                              // Otherwise, show the Select dropdown for other roles
                              <Select
                                menuPortalTarget={document.body}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                    boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                    '&:hover': {
                                      borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                    },
                                    borderRadius: '10px',
                                    fontSize: "14px",
                                    minHeight: "45px",
                                  }),
                                  dropdownIndicator: (base, state) => ({
                                    ...base,
                                    transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                    transition: '250ms',
                                    width: '35px',
                                    height: '35px',
                                  }),
                                }}
                                value={doctorOptions.find(option => option.value === formData.doctor_id)}
                                onChange={(selectedOption) => handleChange({ target: { name: 'doctor_id', value: selectedOption.value } })}
                                options={doctorOptions}
                                components={{
                                  IndicatorSeparator: () => null
                                }}
                                required
                                placeholder="Select Doctor"
                              />
                            )}
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Email
                            </label>
                            <input
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Height <span className="login-danger">*</span>
                            </label>
                            <input
                              name="height"
                              value={formData.height}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Weight <span className="login-danger">*</span>
                            </label>
                            <input
                              name="weight"
                              value={formData.weight}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Symptoms <span className="login-danger">*</span>
                            </label>
                            <input
                              name="symptoms"
                              value={formData.symptoms}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder="Enter symptoms separated by commas"
                              required
                            />
                          </div>
                        </div>


                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Patient UID
                            </label>
                            <input
                              name="patient_uid"
                              value={formData.patient_uid}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12  col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Location
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="address"
                              value={formData.address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Status <span className="login-danger">*</span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input

                                  type="radio"
                                  name="status"
                                  value="Active"
                                  className="form-check-input"
                                  checked={formData.status === 'Active'}
                                  onChange={handleChange}
                                />
                                Active
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="InActive"
                                  className="form-check-input"
                                  checked={formData.status === 'InActive'}
                                  onChange={handleChange}
                                />
                                InActive
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="reset"
                              className="btn btn-primary cancel-form me-2"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Popup box */}
          <div id="popupMessageModal" className="modal fade delete-modal" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center">
                  {isSuccessful ? (
                    <img src={SuccessIcon} width={32} alt="Success" />
                  ) : (
                    <img src={DeleteIcon} width={32} alt="Error" />
                  )}
                  <h3>{popupMessage}</h3>
                  <div className="m-t-20">
                    {isSuccessful ? (
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        data-bs-dismiss="modal"
                        onClick={handleClose}
                        style={{ color: '#009F6F' }}

                        onMouseOver={(e) => e.target.style.color = 'white'}
                        onMouseOut={(e) => e.target.style.color = '#009F6F'}
                      >
                        Close
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        data-bs-dismiss="modal"
                        onClick={handleClose}
                        style={{ color: '#dc3545' }}

                        onMouseOver={(e) => e.target.style.color = 'white'}
                        onMouseOut={(e) => e.target.style.color = '#CA2127'}
                      >
                        Close
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Popup box */}
        </div>
      </>
    </div >
  );
};

export default AddPatients;
