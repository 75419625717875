import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Select from "react-select";
import getClinicId from '../../services/getClinicId';
import patientApi from '../../services/ClientServices/PatientRoutes'; 
import { store } from "../../utils/redux/store";
import doctorApi from "../../services/ClientServices/DoctorRoutes";
import deviceApi from '../../services/DeepFactsServices/DeviceRoutes';
import { Modal } from 'bootstrap';
import { plusicon, refreshicon } from '../imagepath';
import clinicApi from '../../services/ClientServices/ClinicRoutes';

const AssignPatient = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [clinicName, setClinicName] = useState('');
  const [patients, setPatients] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [popupMessage, setPopupMessage] = useState('');
  const [formData, setFormData] = useState({
    bed: '',
    block: '',
    device_mac_id: '',
    doctor_id: '',
    floor: '',
    room: '',
    status: 'Assigned',
    ward: ''
  });
  const [isClinicIdAvailable, setIsClinicIdAvailable] = useState(false);

  const fetchPatients = async () => {
    try {
      const response = await patientApi.getPatients();
      const patientsData = response.patients;
      return patientsData.map(patient => ({
        value: patient.id,
        label: `${patient.first_name} ${patient.last_name}`,
      }));
    } catch (error) {
      console.error('Error fetching patients:', error);
      return [];
    }
  };

  const fetchDoctors = async () => {
    try {
      const response = await doctorApi.getDoctors();
      const doctorsData = response.doctors;
      return doctorsData.map(doctor => ({
        value: doctor.id,
        label: `${doctor.first_name} ${doctor.last_name}`,
      }));
    } catch (error) {
      console.error('Error fetching doctors:', error);
      return [];
    }
  };

  const fetchDevices = async () => {
    try {
      const response = await deviceApi.getDevicesByStatus('Active');
      return response.devices.map(device => ({
        value: device.device_mac_id,
        label: device.device_mac_id,
      }));
    } catch (error) {
      console.error('Error fetching devices:', error);
      return [];
    }
  };

  useEffect(() => {
    const clinic_id = getClinicId(); // Get clinic_id from the function
    if (!clinic_id) {
      console.error("No clinic_id available. Ensure the user is a ClinicAdmin.");
      return; // Exit if clinic_id is not available
    }

    const fetchData = async () => {
      const state = store.getState();
      const client_id = state.auth.client_id;
      const clinic_id = state.auth.clinic_id;
      const role_name = state.auth.userData.role;
      console.log("the role name is",role_name)

      if (!client_id || !clinic_id) {
        console.error("client_id or clinic_id is not available yet.");
        return;
      }

      setIsClinicIdAvailable(true); // Set to true if clinic_id is found

      const { role } = state.auth.userData; 
      if (role === 'DeepFactsSuperAdmin' || role === 'ClientSuperAdmin') {
        await fetchClinicNameForAdmin(client_id, clinic_id);
      } else {
        const clinic_name = state.auth.userData?.name;
        setClinicName(clinic_name);
      }

      const patients = await fetchPatients();
      setPatients(patients);

      const doctors = await fetchDoctors();
      setDoctors(doctors);

      const devices = await fetchDevices();
      setDeviceOptions(devices);
    };

    fetchData(); // Call fetchData here
  }, []); // Empty dependency array to run on mount

  const fetchClinicNameForAdmin = async (clientId, clinicId) => {
    try {
      const response = await clinicApi.readClinic(clinicId, clientId);
      if (response && response.clinics && response.clinics.length > 0) {
        setClinicName(response.clinics[0].name);
      } else {
        console.error("Clinic not found for this client and clinic ID.");
      }
    } catch (error) {
      console.error('Error fetching clinic for admin:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleDoctorChange = (selectedOption) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      doctor_id: selectedOption ? selectedOption.value : ''
    }));
  };

  const handleDeviceChange = (selectedOption) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      device_mac_id: selectedOption ? selectedOption.value : ''
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedOption) {
      console.error('No patient selected');
      return;
    }

    const patient_id = selectedOption.value;
    const assignmentData = {
      ...formData,
      doctor_id: parseInt(formData.doctor_id, 10),
    };

    try {
      const response = await patientApi.assignPatient(patient_id, assignmentData);
      console.log('Patient assigned successfully:', response);
      setPopupMessage(response.message); 
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();

      const patients = await fetchPatients();
      setPatients(patients);

      const doctors = await fetchDoctors();
      setDoctors(doctors);

      const devices = await fetchDevices();
      setDeviceOptions(devices);
    } catch (error) {
      console.error('Error assigning patient:', error);
    }
  };

  const handleRefresh = async () => {
    const state = store.getState();
    const client_id = state.auth.client_id;
    const clinic_id = state.auth.clinic_id;

    if (!client_id || !clinic_id) {
      console.error("client_id or clinic_id is not available.");
      return;
    }

    await fetchClinicNameForAdmin(client_id, clinic_id);
    const patients = await fetchPatients();
    setPatients(patients);

    const doctors = await fetchDoctors();
    setDoctors(doctors);

    const devices = await fetchDevices();
    setDeviceOptions(devices);
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Patient Monitoring</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Assign Patient</li>
                </ul>
              </div>
            </div>
          </div>
       
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="row" style={{ marginBottom: '30px' }}>
                        <div className="doctor-table-blk">
                          <h3>Assign Patient</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk"></div>
                            <div className="add-group">
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                                onClick={handleRefresh}
                              >
                                <img src={refreshicon} alt="Refresh" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>
                            Clinic Name <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                           

                            value={clinicName}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4 ">
                        <div className="form-group local-forms">
                          <label>Patient Name <span className="login-danger">*</span> </label>
                          <Select
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            value={selectedOption}
                            onChange={setSelectedOption}
                            options={patients}
                            id="search-patient"
                            required
                            components={{ IndicatorSeparator: () => null }}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                '&:hover': {
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                },
                                borderRadius: '10px',
                                fontSize: "14px",
                                minHeight: "45px",
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                transition: '250ms',
                                width: '35px',
                                height: '35px',
                              }),
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>Doctor <span className="login-danger">*</span> </label>
                          <Select
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            value={doctors.find(doctor => doctor.value === formData.doctor_id) || null}
                            onChange={handleDoctorChange}
                            options={doctors}
                            id="select-doctor"
                            required
                            components={{ IndicatorSeparator: () => null }}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                '&:hover': {
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                },
                                borderRadius: '10px',
                                fontSize: "14px",
                                minHeight: "45px",
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                transition: '250ms',
                                width: '35px',
                                height: '35px',
                              }),
                            }}
                          />
                        </div>
                      </div>

                      {/* Additional form fields */}
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>Bed</label>
                          <input
                            className="form-control"
                            type="text"
                            name="bed"
                            value={formData.bed}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>Block</label>
                          <input
                            className="form-control"
                            type="text"
                            name="block"
                            value={formData.block}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>Device MAC ID <span className="login-danger">*</span> </label>
                          <Select
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            value={deviceOptions.find(device => device.value === formData.device_mac_id) || null}
                            onChange={handleDeviceChange}
                            options={deviceOptions}
                            id="select-device"
                            required
                            components={{ IndicatorSeparator: () => null }}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                '&:hover': {
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                },
                                borderRadius: '10px',
                                fontSize: "14px",
                                minHeight: "45px",
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                transition: '250ms',
                                width: '35px',
                                height: '35px',
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>Floor</label>
                          <input
                            className="form-control"
                            type="text"
                            name="floor"
                            value={formData.floor}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>Room</label>
                          <input
                            className="form-control"
                            type="text"
                            name="room"
                            value={formData.room}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>Ward</label>
                          <input
                            className="form-control"
                            type="text"
                            name="ward"
                            value={formData.ward}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6 col-xl-6">
                        <div className="form-group select-gender">
                          <label className="gen-label">
                            Status <span className="login-danger">*</span>
                          </label>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name="status"
                                value="Assigned"
                                className="form-check-input"
                                checked={formData.status === 'Assigned'}
                                onChange={handleChange}
                              />
                              Assign
                            </label>
                          </div>
                          <div className="form-check-inline">
                            <label className="form-check-label">
                              <input
                                type="radio"
                                name="status"
                                value="Unassigned"
                                className="form-check-input"
                                checked={formData.status === 'Unassigned'}
                                onChange={handleChange}
                              />
                              UnAssign
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="doctor-submit text-end">
                          <button
                            type="submit"
                            className="btn btn-primary submit-form me-2"
                          >
                            Submit
                          </button>
                          <button
                            type="submit" // Changed from "submit" to "button"
                            className="btn btn-primary cancel-form"
                            onClick={() => {
                              // Reset form or navigate away
                              // Example: Reset form
                              setFormData({
                                bed: '',
                                block: '',
                                device_mac_id: '',
                                doctor_id: '',
                                floor: '',
                                room: '',
                                status: 'Assigned',
                                ward: ''
                              });
                              setSelectedOption(null);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Popup Message Modal */}
      <div id="popupMessageModal" className="modal fade delete-modal" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h3>{popupMessage}</h3> {/* Display API message here */}
              <div className="m-t-20">
                <button
                  type="button"
                  className="btn btn-white me-2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of Popup Message Modal */}
    </div>
  );
};

export default AssignPatient;
