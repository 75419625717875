// src/services/ClientServices/ClientRoutes.js

import apiClient from '../apiservice';

const clientApi = {
  createClient: async (clientData) => {
    try {
      const response = await apiClient.post(`/clients/df_userid/1/add_client`, clientData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error creating client:', error);
      throw error;
    }
  },
  getClients: async () => {
    try {
      const response = await apiClient.get(`/clients/get_all_clients`,  { timeout: 500000 });
      return response.data;
    } catch (error) {
      console.error('Error fetching clients:', error);
      throw error;
    }
  },
  //Read Client
  readClient: async (client_id) => {
    try { 
      const response = await apiClient.get(`/clients/${client_id}/get_client`);
      return response.data;
    } catch (error) {
      console.error('Error reading client:', error);
      throw error;
    }
  },
  //Update Client
  updateClient: async (client_id, clientData) => {
    try {
      console.log('Data being sent to update client:', clientData); // Log the data before sending
      const response = await apiClient.put(`/clients/${client_id}/update`, clientData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error updating client:', error);
      throw error;
    }
  },
};

export default clientApi;

