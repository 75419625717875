// src/services/ClientServices/PatientRoutes.js
import apiClient from '../apiservice';
import getClinicId from '../getClinicId';

const patientApi = {
    addPatient: async (patientData) => {
        try {
            const { clinic_id} = getClinicId(); // Get the dynamic clinic_id
            const response = await apiClient.post(`/clinics/${clinic_id}/add_patient`, patientData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error adding patient:', error);
            throw error;
        }
    },
    getPatients: async () => {
        try {
            const { clinic_id} = getClinicId();
            const response = await apiClient.get(`/clinics/${clinic_id}/get_all_patients`);
            return response.data;
        } catch (error) {
            console.error('Error fetching patients:', error);
            throw error;
        }
    },
    readPatient: async (patient_id) => {
        try {
            const { clinic_id } = getClinicId();
            const response = await apiClient.get(`/clinics/${clinic_id}/patients/${patient_id}/get_patient`);
            return response.data;
        } catch (error) {
            console.error('Error reading patient:', error);
            throw error;
        }
    },
    updatePatient: async (patient_id, patientData) => {
        try {
            const { clinic_id} = getClinicId();
            const response = await apiClient.put(`/clinics/${clinic_id}/patients/${patient_id}/update`, patientData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error updating patient:', error);
            throw error;
        }
    },
    assignPatient: async (patient_id, assignmentData) => {
        try {
            const { clinic_id, error } = getClinicId();
            const response = await apiClient.post(`/clinics/${clinic_id}/assign_patient/${patient_id}`, assignmentData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error assigning patient:', error);
            throw error;
        }
    },
    getAssignedPatients: async () => {
        try {
            const { clinic_id} = getClinicId();
            const response = await apiClient.get(`/clinics/${clinic_id}/get_all_assigned_patients`);
            return response.data;
        } catch (error) {
            console.error('Error fetching assigned patients:', error);
            throw error;
        }
    },
    unassignPatient: async (macId, patientId, clinicId) => {
        try {
          const response = await apiClient.post(`/clinics/${clinicId}/devices/${macId}/unassign_patient/${patientId}`);
          return response.data;
        } catch (error) {
          console.error('Error unassigning patient:', error);
          throw error;
        }
      },
};

export default patientApi;
