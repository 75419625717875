/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { DatePicker } from "antd";
import Select from "react-select";
import moment from 'moment';
import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import nurseApi from "../../services/ClientServices/NurseRoutes";
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
import CreatableSelect from 'react-select/creatable';
import usePostalCode from "../../hooks/usePostalCode";
import SuccessIcon from '../../assets/img/alert-success.256x256.png';
import DeleteIcon from "../../assets/img/delete.png";

const AddNurse = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState([]);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState(null);
  const [selectedDateOfBirth, setSelectedDateOfBirth] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();

  const departmentOptions = [
    { value: "Cardiology", label: "Cardiology" },
    { value: "Orthopedic", label: "Orthopedic" },
    { value: "Radiology", label: "Radiology" },
    { value: "Pediatrics", label: "Pediatrics" },
    { value: "Oncology", label: "Oncology" },
  ];

  const specialityOptions = [
    { value: "Surgical Nurse", label: "Surgical Nurse" },
    { value: "Critical Care", label: "Critical Care" },
    { value: "Nurse Practitioner", label: "Nurse Practitioner" },
    { value: "Clinical Nurse Specialist", label: "Clinical Nurse Specialist" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    account_holder_name: "",
    account_number: "",
    ifsc_code: "",
    bank_branch: "",
    pan_number: "",
    facebook_id: "",
    twitter_id: "",
    linkedin_id: "",
    instagram_id: "",
    address: "",
    pin_code: "",
    city: "",
    state: "",
    country: "",
    mobile: "",
    email: "",
    date_of_birth: "",
    aadhar_number: "",
    degree: "",
    blood_group: "",
    specialities: [],
    license_number: "",
    departments: [],
    status: "",
    picture: null,
    signature: null,
    gender: "",
  });

  const {
    handlePincodeChange,
    cityOptions,
    stateOptions,
    countryOptions,
    handleCityChange,
    handleStateChange,
    handleCountryChange
  } = usePostalCode();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();

    // Create a new object to hold the form data for nurse_request
    const nurseRequest = {};

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        if (key === 'departments' || key === 'specialities') {
          // Handle departments and specialities as arrays
          nurseRequest[key] = formData[key];
        } else if (key === 'pin_code') {
          // Convert pin_code to integer
          nurseRequest[key] = parseInt(formData[key], 10);
        } else if (key === 'date_of_birth') {
          // Convert date_of_birth to ISO string if it's a Date object
          nurseRequest[key] = new Date(formData[key]).toISOString();
        } else {
          nurseRequest[key] = formData[key];
        }
      }
    });

    // Append the nurse_request JSON string to FormData
    data.append('nurse_request', JSON.stringify(nurseRequest));

    // Append files if they exist
    if (formData.picture) data.append('picture', formData.picture);
    if (formData.signature) data.append('signature', formData.signature);

    // Log FormData entries
    for (let [key, value] of data.entries()) {
      if (value instanceof File) {
        console.log(`${key}: [File: ${value.name}]`);
      } else {
        console.log(`${key}: ${value}`);
      }
    }

    try {
      const response = await nurseApi.addNurse(data);
      console.log('Nurse added successfully:', response);

      if (response.status === 409) {
        setPopupMessage(response.message);
        setIsSuccessful(false);
      } else {
        setPopupMessage(response.message);
        setIsSuccessful(true);
      }

      setPopupMessage(response.message); // Set the message from API response

      // Show the modal with Bootstrap JavaScript
      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    } catch (error) {
      console.error('Error adding nurse:', error);
      setPopupMessage('Failed to create nurse. Please try again.');
      setIsSuccessful(false);

      const popupMessageModal = new Modal(document.getElementById('popupMessageModal'));
      popupMessageModal.show();
    }
  };

  const handleDepartmentChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.__isNew__ ? option.value : option.value)
      : [];
    setFormData({
      ...formData,
      departments: selectedValues,
    });
    setSelectedDepartment(selectedOptions);
  };

  const handleSpecialityChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.__isNew__ ? option.value : option.value)
      : [];
    setFormData({
      ...formData,
      specialities: selectedValues, // Ensure this is an array
    });
    setSelectedSpeciality(selectedOptions);
  };

  const handleBloodGroupChange = (selectedOption) => {
    setSelectedBloodGroup(selectedOption);
    setFormData({
      ...formData,
      blood_group: selectedOption ? selectedOption.value : '',
    });
  };

  const handleDateOfBirthChange = (date) => {
    if (date) {
      const formattedDate = date.format('YYYY-MM-DD');
      setSelectedDateOfBirth(date);
      setFormData({
        ...formData,
        date_of_birth: formattedDate,
      });
    } else {
      setSelectedDateOfBirth(null);
      setFormData({
        ...formData,
        date_of_birth: null,
      });
    }
  };

  const bloodGroupOptions = [
    { value: "A+", label: "A+" },
    { value: "A-", label: "A-" },
    { value: "B+", label: "B+" },
    { value: "B-", label: "B-" },
    { value: "AB+", label: "AB+" },
    { value: "AB-", label: "AB-" },
    { value: "O+", label: "O+" },
    { value: "O-", label: "O-" },
    { value: "None", label: "None" },
  ];

  const onChange = (date, dateString) => {
    // console.log(date, dateString);
    setIsClicked(true);
  };
  const loadFile = (event) => {
    // Handle file loading logic here
  };

  const handleClose = () => {
    // Close the modal programmatically
    const popupMessageModal = Modal.getInstance(document.getElementById('popupMessageModal'));
    popupMessageModal.hide();
    if (isSuccessful) {
      navigate('/nurse-list'); // Redirect to doctor list if successful
    }
  };


  return (
    <div>
      {/*<Header /> <Sidebar id="menu-item3" id1="menu-items3" activeClassName="add-nurse" />*/}
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Nurses </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Add Nurse</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Nurse Details</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              First Name <span className="login-danger">*</span>
                            </label>
                            <input
                              name="first_name"
                              value={formData.first_name}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Last Name <span className="login-danger">*</span>
                            </label>
                            <input
                              name="last_name"
                              value={formData.last_name}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms cal-icon">
                            <label>
                              Date Of Birth <span className="login-danger">*</span>
                            </label>
                            <DatePicker
                              className="form-control datetimepicker"
                              value={selectedDateOfBirth}
                              onChange={handleDateOfBirthChange}
                              format="DD-MM-YYYY"
                              wrapperClassName="date-picker"
                              style={{
                                borderColor: selectedDateOfBirth ? '#2E37A4' : '2px solid rgba(46, 55, 164, 0.1)',
                              }}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Mobile <span className="login-danger">*</span>
                            </label>
                            <input
                              name="mobile"
                              value={formData.mobile}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                              maxLength={10}
                              minLength={10}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Email <span className="login-danger">*</span>
                            </label>
                            <input
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              className="form-control"
                              type="email"
                              placeholder=""
                              required
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Gender <span className="login-danger">*</span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Male"
                                  className="form-check-input"
                                  checked={formData.gender === 'Male'}
                                  onChange={handleChange}
                                />
                                Male
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Female"
                                  className="form-check-input"
                                  checked={formData.gender === 'Female'}
                                  onChange={handleChange}
                                />
                                Female
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  value="Other"
                                  className="form-check-input"
                                  checked={formData.gender === 'Other'}
                                  onChange={handleChange}
                                />
                                Others
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Department <span className="login-danger">*</span>
                            </label>
                            <CreatableSelect
                              name="departments"
                              menuPosition={'fixed'}
                              value={selectedDepartment}
                              onChange={handleDepartmentChange}
                              options={departmentOptions}
                              menuPortalTarget={document.body}
                              required
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                              id="search-commodity"
                              components={{ IndicatorSeparator: () => null }}
                              isMulti
                              isClearable
                            />
                          </div>
                        </div>

                        
                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Blood Group 
                            </label>
                            <Select
                              name="blood_group"
                              value={selectedBloodGroup}
                              onChange={handleBloodGroupChange}
                              options={bloodGroupOptions}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                              components={{
                                IndicatorSeparator: () => null
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Medical License Number 
                            </label>
                            <input
                              name="license_number"
                              value={formData.license_number}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Specialization 
                            </label>
                            <CreatableSelect
                              name="specialities"
                              menuPosition={'fixed'}
                              value={selectedSpeciality}
                              onChange={handleSpecialityChange}
                              options={specialityOptions}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                              id="search-commodity"
                              components={{ IndicatorSeparator: () => null }}
                              isClearable
                              isMulti={true}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-4">
                          <div className="form-group local-forms">
                            <label>
                              Degree
                            </label>
                            <input
                              name="degree"
                              value={formData.degree}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="row" style={{ marginBottom: '20px' }}>
                          <div className="form-group theme-set col-12 col-md-6 col-xl-6">
                            <label
                              className="local-top"
                              style={{
                                fontSize: '12px',
                                color: '#333448',
                                fontWeight: 500,
                                background: '#fff',
                                marginTop: 0,
                                marginBottom: 0,
                                padding: '0px 5px',
                              }}
                            >
                              Nurse Picture
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              name="picture"
                              accept="image/*"
                              id="file"
                              onChange={handleFileChange}
                            />
                            <span className="form-text text-muted" style={{
                              fontSize: '12px', marginBottom: '14px'
                            }}>
                              Recommended image size is 40px x 40px
                            </span>
                          </div>

                          <div className="form-group theme-set col-12 col-md-6 col-xl-6">
                            <label
                              className="local-top"
                              style={{
                                fontSize: '12px',
                                color: '#333448',
                                fontWeight: 500,
                                background: '#fff',
                                marginTop: 0,
                                marginBottom: 0,
                                padding: '0px 5px',
                              }}
                            >
                              Nurse Signature
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              name="signature"
                              accept="image/*"
                              id="file"
                              onChange={handleFileChange}
                            />
                            <span className="form-text text-muted" style={{
                              fontSize: '12px', marginBottom: '14px'
                            }}>
                              Recommended image size is 40px x 40px
                            </span>
                          </div>
                        </div>

                        <div className="col-12 col-sm-12">
                          <div className="form-group local-forms">
                            <label>
                              Address
                            </label>
                            <textarea
                              className="form-control"
                              rows={3}
                              cols={30}
                              name="address"
                              value={formData.address}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>Postal Code</label>
                            <input
                              name="pin_code"
                              className="form-control"
                              type="text"
                              placeholder=""
                              value={formData.pin_code}
                              minLength={6}
                              maxLength={6}
                              onChange={(e) => handlePincodeChange(e.target.value, setFormData)}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>City</label>
                            <Select
                              options={cityOptions}
                              value={cityOptions.find(option => option.value === formData.city)}
                              onChange={(selectedOption) => handleCityChange(selectedOption, setFormData)}
                              menuPortalTarget={document.body}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>Country</label>
                            <Select
                              menuPosition={'fixed'}
                              menuPortalTarget={document.body}
                              options={countryOptions}
                              value={countryOptions.find(option => option.value === formData.country)}
                              onChange={(selectedOption) => handleCountryChange(selectedOption, setFormData)}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              id="search-commodity"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>State/Province</label>
                            <Select
                              menuPosition={'fixed'}
                              menuPortalTarget={document.body}
                              options={stateOptions}
                              value={stateOptions.find(option => option.value === formData.state)}
                              onChange={(selectedOption) => handleStateChange(selectedOption, setFormData)}
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                              id="search-commodity"
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                  minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Aadhaar Number
                            </label>
                            <input
                              name="aadhar_number"
                              value={formData.aadhar_number}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Pan Number
                            </label>
                            <input
                              name="pan_number"
                              value={formData.pan_number}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Account Name
                            </label>
                            <input
                              name="account_holder_name"
                              value={formData.account_holder_name}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Account Number
                            </label>
                            <input
                              name="account_number"
                              value={formData.account_number}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              IFSC Code
                            </label>
                            <input
                              name="ifsc_code"
                              value={formData.ifsc_code}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Bank Branch
                            </label>
                            <input
                              name="bank_branch"
                              value={formData.bank_branch}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Facebook ID
                            </label>
                            <input
                              name="facebook_id"
                              value={formData.facebook_id}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Instagram ID
                            </label>
                            <input
                              name="instagram_id"
                              value={formData.instagram_id}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              Twitter ID
                            </label>
                            <input
                              name="twitter_id"
                              value={formData.twitter_id}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-3">
                          <div className="form-group local-forms">
                            <label>
                              LinkedIn ID
                            </label>
                            <input
                              name="linkedin_id"
                              value={formData.linkedin_id}
                              onChange={handleChange}
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>

                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Status <span className="login-danger">*</span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="Active"
                                  checked={formData.status === 'Active'}
                                  className="form-check-input"
                                  onChange={handleChange}
                                />
                                Active
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="status"
                                  value="InActive"
                                  checked={formData.status === 'InActive'}
                                  className="form-check-input"
                                  onChange={handleChange}
                                />
                                InActive
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Submit
                            </button>
                            <button
                              type="reset"
                              className="btn btn-primary cancel-form"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Notifications - Box */}
        </div>
        {/* Popup box */}
        <div id="popupMessageModal" className="modal fade delete-modal" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center">
                {isSuccessful ? (
                  <img src={SuccessIcon} width={32} alt="Success" />
                ) : (
                  <img src={DeleteIcon} width={32} alt="Error" />
                )}
                <h3>{popupMessage}</h3>
                <div className="m-t-20">
                  {isSuccessful ? (
                    <button
                      type="button"
                      className="btn btn-outline-success"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#009F6F' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#009F6F'}
                    >
                      Close
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                      style={{ color: '#dc3545' }}

                      onMouseOver={(e) => e.target.style.color = 'white'}
                      onMouseOut={(e) => e.target.style.color = '#CA2127'}
                    >
                      Close
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Popup box */}
      </>
    </div>
  );
};

export default AddNurse;
