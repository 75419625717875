/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Table, ConfigProvider } from "antd";
import { onShowSizeChange, itemRender } from '../Pagination';
import {
  blogimg10, imagesend, pdficon, pdficon3, pdficon4, plusicon, refreshicon, searchnormal, blogimg12,
  blogimg2, blogimg4, blogimg6, blogimg8
} from '../imagepath';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import nurseApi from '../../services/ClientServices/NurseRoutes';
import StatusButton from '../../statusbutton';
import { useNurse } from '../../context/NurseContext';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { store } from "../../../src/utils/redux/store";
import { error02, dangericon } from '../imagepath';

const NurseList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const navigate = useNavigate();
  const [nurses, setNurses] = useState([]);
  const { setNurseId } = useNurse();
  const location = useLocation();
  const auth = store.getState().auth; // Access the auth state from Redux store
  const [error, setError] = useState(null);
  const [filteredData, setFilteredData] = useState([]); 
  const [searchQuery, setSearchQuery] = useState('');

  // Function to fetch nurses data
  const fetchNurses = async () => {
    try {
      const data = await nurseApi.getNurses();
      setNurses(data.nurses || []);
      setFilteredData(data.nurses || []);
      setError(null);
    } catch (error) {
      setError(error);
      setDataSource([]); // Set empty array on error
    }
  };

  // useEffect hook to fetch nurses after the component mounts and when auth changes
  useEffect(() => {
    if (auth.authenticated && auth.clinic_id) {
      fetchNurses();
    }
  }, [auth.authenticated, auth.clinic_id]); // Dependencies ensure fetchNurses runs after these are available

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);

    // Filter nurses based on search query
    const filtered = nurses.filter((nurse) => {
      const fullName = `${nurse.first_name} ${nurse.last_name}`.toLowerCase();
      const searchValue = value.toLowerCase();

      return (
        fullName.includes(searchValue) || 
        nurse.mobile.includes(searchValue) || 
        nurse.email.toLowerCase().includes(searchValue) ||
        nurse.departments.join(", ").toLowerCase().includes(searchValue) ||
        nurse.specialities.join(", ").toLowerCase().includes(searchValue) ||
        nurse.status.toLowerCase().includes(searchValue)
      );
    });

    setFilteredData(filtered);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleEditClick = (id) => {
    setNurseId(id);
    navigate('/edit-nurse');
  };

  const handleViewClick = (id) => {
    setNurseId(id);
    navigate('/edit-nurse', { state: { type: 'view' } });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "first_name",
      render: (text, record) => `${record.first_name} ${record.last_name}`,
      sorter: (a, b) => a.first_name.localeCompare(b.first_name)
    },
    {
      title: "Department",
      dataIndex: "departments",
      render: (departments) => departments.join(", "),
      sorter: (a, b) => a.departments.length - b.departments.length
    },
    {
      title: "Specialization",
      dataIndex: "specialities",
      render: (specialities) => specialities.join(", "),
      sorter: (a, b) => a.specialities.length - b.specialities.length
    },
   
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
      render: (text, record) => (
        <Link to="#">{record.mobile}</Link>
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status , record) => <StatusButton
       status={status}
       mobileNumber={record.mobile} 
       isMobileVerified={record.is_mobile_verified}
       isEmailVerified={record.is_email_verified}
       userEmail={record.email}
       onPatientStatusClick={() => console.log(`Status: ${status}`)} />,
    },
    {
      title: "",
      dataIndex: "actions",
      render: (text, record) => (
        <div className="text-end">
          <button onClick={() => handleEditClick(record.id)} className="btn btn-primary me-2">Edit</button>
          <button onClick={() => handleViewClick(record.id)} className="btn btn-secondary">View</button>

          {/* Other actions like Delete can be added here */}
        </div>
      ),
    }
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Nurses</a>
                </li>
                <li className="breadcrumb-item">
                  <i className="feather-chevron-right">
                    <FeatherIcon icon="chevron-right" />
                  </i>
                </li>
                <li className="breadcrumb-item active">Nurse List</li>
              </ul>
            </div>
          </div>
        </div>
        {error ? (
            <div className="main-wrapper error-wrapper">
              <div className="error-box">
                <img className="img-fluid" src={error02} alt="Logo" />
                <h3>
                  <img
                    className="img-fluid mb-0"
                    src={dangericon}
                    alt="Logo"
                  />{" "}
                  Service Unavailable
                </h3>
                <p>Sorry, an error has occured, and we're working to fix the problem.</p>
                <Link to="/" className="btn btn-primary go-home">
                  Back to Home
                </Link>
              </div>
            </div>
          ) : (
        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table show-entire">
              <div className="card-body">
                <div className="page-table-header mb-2">
                  <div className="row align-items-center">
                    <div className="col">
                      <div className="doctor-table-blk">
                        <h3>Nurse List</h3>
                        <div className="doctor-search-blk">
                          <div className="top-nav-search table-search-blk">
                            <form>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search here"
                                value={searchQuery}
                                onChange={handleSearch}
                              />
                              <Link className="btn">
                                <img src={searchnormal} alt="#" />
                              </Link>
                            </form>
                          </div>
                          <div className="add-group">
                            <Link
                              to="/add-nurse"
                              className="btn btn-primary add-pluss ms-2"
                            >
                              <img src={plusicon} alt="#" />
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-primary doctor-refresh ms-2"
                              onClick={fetchNurses}
                            >
                              <img src={refreshicon} alt="#" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive doctor-list">
                  <ConfigProvider theme={{
                    components: {
                      Table: {
                        headerBg: 'red'
                      },
                    },
                  }}>
                    <Table
                      pagination={{
                        total: nurses.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={filteredData}
                      rowSelection={{
                        selectedRowKeys,
                        onChange: onSelectChange
                      }}
                      rowKey={(record) => record.id}
                    />

                  </ConfigProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
      </div>
      {/* Modal for deletion (if needed) */}
    </div>
  );
};

export default NurseList;


